import React from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Autocomplete from '@mui/material/Autocomplete';
import {communities} from './data';
import {validationSchema} from  './validation';

const priorities = [
  { label: 'Acquire or develop my skills', value: 'Acquire or develop my skills' },
  { label: 'Get a job', value: 'Get a job' },
  { label: 'Get scholarship opportunities', value: 'Get scholarship opportunities' },
  { label: 'Contribute to my community through civic participation', value: 'Contribute to my community through civic participation' },
  { label: 'Run for an office', value: 'Run for an office' },
];

const skillSet = [
  { label: 'Leadership', value: 'Leadership' },
  { label: 'Civic principles', value: 'Civic principles' },
  { label: 'Digital communication', value: 'Digital communication' },
  { label: 'Letter writing & constructive engagement', value: 'Letter writing & constructive engagement' },
  { label: 'Data analysis', value: 'Data analysis' },
  { label: 'All of the above', value: 'All of the above' },
];

const Form = () => {

  const { push } = useHistory();
  const initialValues = {
    email: '',
    gender: '',
    age: '',
    education: '',
    occupation: '',
    lga: '',
    community: '',
    others: '',
    income: '',
    priority: [],
    civic1: '',
    civic2: '',
    civic3: '',
    civic4: '',
    civic5: '',
    civic6: '',
    civic7: '',
    civic8: '',
    civic9: '',
    civic10: '',
    civic11: '',
    civic12: '',
    civic13: '',
    civic14: '',
    skill: [],
    violence1: '',
    violence2: '',
    violence3: '',
    violence4: '',
    violence5: '',
    violence6: '',
    medium: '',
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const newRequest = {
      email: formik.values.email,
      gender: formik.values.gender,
      age: formik.values.age,
      education: formik.values.education,
      occupation: formik.values.occupation,
      lga: formik.values.lga,
      community: formik.values.community,
      others: formik.values.others,
      income: formik.values.income,
      priority: formik.values.priority,
      civic1: formik.values.civic1,
      civic2: formik.values.civic2,
      civic3: formik.values.civic3,
      civic4: formik.values.civic4,
      civic5: formik.values.civic5,
      civic6: formik.values.civic6,
      civic7: formik.values.civic7,
      civic8: formik.values.civic8,
      civic9: formik.values.civic9,
      civic10: formik.values.civic10,
      civic11: formik.values.civic11,
      civic12: formik.values.civic12,
      civic13: formik.values.civic13,
      civic14: formik.values.civic14,
      skill: formik.values.skill,
      violence1: formik.values.violence1,
      violence2: formik.values.violence2,
      violence3: formik.values.violence3,
      violence4: formik.values.violence4,
      violence5: formik.values.violence5,
      violence6: formik.values.violence6,
      medium: formik.values.medium,
    };

    axios
      .post('https://app-grap.herokuapp.com/api/youth', newRequest)
      .then((res) => {
        console.log(res);
        push('/NEEDS-assessment-survey');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ColorButton = styled(Button)(() => ({
    color: '#fff',
    width: 250,
    height: 55,
    fontWeight: 600,
    fontSize: '18px',
    textTransform: 'capitalize',
    backgroundColor: '#F58634',
    borderColor: '#F58634',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#81B214',
      borderColor: '#81B214',
    },
  }));

  return (
    <Box marginTop={{ xs: '22%', sm: '8%' }}>
      <Box marginBottom={4}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
          }}
          color="#F58634"
        >
          FCT Youth NEEDS Assessment on Civic Participation
        </Typography>
        <Typography variant="body1" color="#81B214" paddingTop={'3%'}>
          To accelerate youth civic participation and inclusion in the Federal
          Capital Territory (FCT), PROMAD is gathering evidence on the factors
          that limit youth participation and inclusion. This is to provide
          insights for advocacy and empowerment efforts for young people in the
          FCT.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Email Address
            </Typography>
            <TextField
              label="Email Address"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Gender
            </Typography>
            <FormControl required fullWidth>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                labelId="gender"
                id="gender"
                label="Gender"
                name={'gender'}
                fullWidth
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
              >
                <MenuItem value={'Male'}>Male</MenuItem>
                <MenuItem value={'Female'}>Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Local Government Area
            </Typography>
            <FormControl required fullWidth>
              <InputLabel id="lga">Local Government Area</InputLabel>
              <Select
                labelId="lga"
                id="lga"
                label="Local Government Area"
                name={'lga'}
                fullWidth
                value={formik.values.lga}
                onChange={formik.handleChange}
                error={formik.touched.lga && Boolean(formik.errors.lga)}
                helperText={formik.touched.lga && formik.errors.lga}
              >
                <MenuItem value={'AMAC'}>Abuja Municipal Area Council</MenuItem>
                <MenuItem value={'Abaji'}>Abaji Area Council</MenuItem>
                <MenuItem value={'Bwari'}>Bwari Area Council</MenuItem>
                <MenuItem value={'Gwagwalada'}>Gwagwalada Area Council</MenuItem>
                <MenuItem value={'Kwali'}>Kwali Area Council</MenuItem>
                <MenuItem value={'Kuje'}>Kuje Area Council</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Community
            </Typography>
            <Autocomplete
              fullWidth
              name={'community'}
              onChange={(event, newValue) => {
                formik.setFieldValue('community', newValue);
              }}
              id="community"
              value={formik.values.community}
              options={communities}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={'community'}
                  required
                  label="Community"
                  error={formik.touched.community && Boolean(formik.errors.community)}
                  helperText={formik.touched.community && formik.errors.community}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              If Others
            </Typography>
            <TextField
              label="Others"
              variant="outlined"
              name={'others'}
              fullWidth
              value={formik.values.others}
              onChange={formik.handleChange}
              error={
                formik.touched.others && Boolean(formik.errors.others)
              }
              helperText={formik.touched.others && formik.errors.others}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Education Qualification
            </Typography>
            <FormControl required fullWidth>
              <InputLabel id="education">Education Qualification</InputLabel>
              <Select
                labelId="education"
                id="education"
                label="Education Qualification"
                name={'education'}
                fullWidth
                value={formik.values.education}
                onChange={formik.handleChange}
                error={
                  formik.touched.education && Boolean(formik.errors.education)
                }
                helperText={formik.touched.education && formik.errors.education}
              >
                <MenuItem value={'Post-graduate'}>Post-graduate</MenuItem>
                <MenuItem value={'Graduate'}>Graduate</MenuItem>
                <MenuItem value={'Secondary School'}>Secondary School</MenuItem>
                <MenuItem value={'Primary School'}>Primary School</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Age Range
            </Typography>
            <FormControl required fullWidth>
              <InputLabel id="age">Age Range</InputLabel>
              <Select
                labelId="age"
                id="age"
                label="Age Range"
                name={'age'}
                fullWidth
                value={formik.values.age}
                onChange={formik.handleChange}
                error={formik.touched.age && Boolean(formik.errors.age)}
                helperText={formik.touched.age && formik.errors.age}
              >
                <MenuItem value={'18-24'}>18-24</MenuItem>
                <MenuItem value={'25-31'}>25-30</MenuItem>
                <MenuItem value={'32-39'}>31-35</MenuItem>
                <MenuItem value={'40-45'}>36 +</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'h6'} fontWeight={700}>
              Access to Economic Power
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Are you employed?
            </Typography>
            <FormControl required fullWidth>
              <InputLabel id="occupation">Are you employed?</InputLabel>
              <Select
                labelId="occupation"
                id="occupation"
                label="Are you employed?"
                name={'occupation'}
                fullWidth
                value={formik.values.occupation}
                onChange={formik.handleChange}
                error={
                  formik.touched.occupation && Boolean(formik.errors.occupation)
                }
                helperText={
                  formik.touched.occupation && formik.errors.occupation
                }
              >
                <MenuItem value={'Yes, by govt '}>Yes, by govt </MenuItem>
                <MenuItem value={'Yes, the private sector '}>
                  Yes, the private sector{' '}
                </MenuItem>
                <MenuItem value={'Yes, NGO/CSO'}>Yes, NGO/CSO</MenuItem>
                <MenuItem value={'No,I’m a student'}>No,I’m a student</MenuItem>
                <MenuItem value={'No, I’m unemployed'}>
                  No, I’m unemployed
                </MenuItem>
                <MenuItem value={'Self-employed'}>Self-employed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Do you have a source(s) of income?
            </Typography>
            <FormControl required fullWidth>
              <InputLabel id="income">
                Do you have a source(s) of income?
              </InputLabel>
              <Select
                labelId="income"
                id="income"
                label="Do you have a source(s) of income?"
                name={'income'}
                fullWidth
                value={formik.values.income}
                onChange={formik.handleChange}
                error={formik.touched.income && Boolean(formik.errors.income)}
                helperText={formik.touched.income && formik.errors.income}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'I’m a dependant'}>I’m a dependant</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              What is most important to you right now is to? (tick them)
            </Typography>
            <FormControl required fullWidth>
              <InputLabel id="priority">
                What is most important to you right now is to?
              </InputLabel>
              <Select
                labelId="priority"
                id="priority"
                name={'priority'}
                multiple
                fullWidth
                value={formik.values.priority}
                onChange={formik.handleChange}
                input={<OutlinedInput label="What is most important to you right now is to?" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {priorities.map((priority) => (
                  <MenuItem key={priority.value} value={priority.value}>
                    <Checkbox checked={name.indexOf(priority.value) > -1}/>
                    <ListItemText primary={priority.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'h6'} fontWeight={700}>
              Civic and Political Participation and Inclusion
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Do you belong to any youth group for political participation?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="civic1"
                id="civic1"
                name={'civic1'}
                fullWidth
                value={formik.values.civic1}
                onChange={formik.handleChange}
                error={formik.touched.civic1 && Boolean(formik.errors.civic1)}
                helperText={formik.touched.civic1 && formik.errors.civic1}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'Not interested'}>Not interested</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Do you have a Permanent Voter Card (PVC)?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="civic2"
                id="civic2"
                name={'civic2'}
                fullWidth
                value={formik.values.civic2}
                onChange={formik.handleChange}
                error={formik.touched.civic2 && Boolean(formik.errors.civic2)}
                helperText={formik.touched.civic2 && formik.errors.civic2}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'Yet to collect my PVC'}>
                  Yet to collect my PVC
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Do you know where to collect your PVC?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="civic3"
                id="civic3"
                name={'civic3'}
                fullWidth
                value={formik.values.civic3}
                onChange={formik.handleChange}
                error={formik.touched.civic3 && Boolean(formik.errors.civic3)}
                helperText={formik.touched.civic3 && formik.errors.civic3}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'I don’t want it'}>I don’t want it</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Have you ever voted in any election?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="civic4"
                id="civic4"
                required
                name={'civic4'}
                fullWidth
                value={formik.values.civic4}
                onChange={formik.handleChange}
                error={formik.touched.civic4 && Boolean(formik.errors.civic4)}
                helperText={formik.touched.civic4 && formik.errors.civic4}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'I don’t vote'}>I don’t vote</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Are you going to vote in the 2023 general elections?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="civic5"
                id="civic5"
                required
                name={'civic5'}
                fullWidth
                value={formik.values.civic5}
                onChange={formik.handleChange}
                error={formik.touched.civic5 && Boolean(formik.errors.civic5)}
                helperText={formik.touched.civic5 && formik.errors.civic5}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'Yes, as a first-time voter'}>
                  Yes, as a first-time voter{' '}
                </MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'I’m not interested'}>
                  I’m not interested
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Are you a member of any political party?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="civic6"
                id="civic6"
                required
                name={'civic6'}
                fullWidth
                value={formik.values.civic6}
                onChange={formik.handleChange}
                error={formik.touched.civic6 && Boolean(formik.errors.civic6)}
                helperText={formik.touched.civic6 && formik.errors.civic6}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'Not interested'}>Not interested</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Have you benefited from the constituency or government youth
              empowerment schemes?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="civic7"
                id="civic7"
                required
                name={'civic7'}
                fullWidth
                value={formik.values.civic7}
                onChange={formik.handleChange}
                error={formik.touched.civic7 && Boolean(formik.errors.civic7)}
                helperText={formik.touched.civic7 && formik.errors.civic7}
              >
                <MenuItem value={'Yes, through NASS Representatives'}>
                  Yes, through NASS Representatives
                </MenuItem>
                <MenuItem value={'Yes, through government agency'}>
                  Yes, through government agency
                </MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Have you engaged your representatives and government on youth
              employment and empowerment?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="civic8"
                id="civic8"
                required
                name={'civic8'}
                fullWidth
                value={formik.values.civic8}
                onChange={formik.handleChange}
                error={formik.touched.civic8 && Boolean(formik.errors.civic8)}
                helperText={formik.touched.civic8 && formik.errors.civic8}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Has the Representative in your community organised a town hall
              meeting or consultation session in the past 12 months?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="civic9"
                id="civic9"
                required
                name={'civic9'}
                fullWidth
                value={formik.values.civic9}
                onChange={formik.handleChange}
                error={formik.touched.civic9 && Boolean(formik.errors.civic9)}
                helperText={formik.touched.civic9 && formik.errors.civic9}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'I’m not aware'}>I’m not aware</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              What is the major hindrance preventing you from participating in
              politics and elections?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="civic10"
                id="civic10"
                name={'civic10'}
                fullWidth
                value={formik.values.civic10}
                onChange={formik.handleChange}
                error={formik.touched.civic10 && Boolean(formik.errors.civic10)}
                helperText={formik.touched.civic10 && formik.errors.civic10}
              >
                <MenuItem value={'High cost of participation'}>
                  High cost of participation
                </MenuItem>
                <MenuItem value={'Violence'}>Violence</MenuItem>
                <MenuItem value={'Corruption'}>Corruption</MenuItem>
                <MenuItem value={'Youth only meant for thuggery'}>
                  Youth only meant for thuggery
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              How many youths in your local government area (LGA) are elected
              into office?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="civic11"
                id="civic11"
                name={'civic11'}
                fullWidth
                value={formik.values.civic11}
                onChange={formik.handleChange}
                error={formik.touched.civic11 && Boolean(formik.errors.civic11)}
                helperText={formik.touched.civic11 && formik.errors.civic11}
              >
                <MenuItem value={'Less than 5'}>Less than 5</MenuItem>
                <MenuItem value={'More than 5'}>More than 5</MenuItem>
                <MenuItem value={'More than 10'}>More than 10</MenuItem>
                <MenuItem value={'Less than 20'}>Less than 20</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
                <MenuItem value={'Not aware'}>Not aware</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              How many youths in your local government area (LGA) are appointed
              into government?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="civic12"
                id="civic12"
                name={'civic12'}
                fullWidth
                value={formik.values.civic12}
                onChange={formik.handleChange}
                error={formik.touched.civic12 && Boolean(formik.errors.civic12)}
                helperText={formik.touched.civic12 && formik.errors.civic12}
              >
                <MenuItem value={'Less than 5'}>Less than 5</MenuItem>
                <MenuItem value={'More than 5'}>More than 5</MenuItem>
                <MenuItem value={'More than 10'}>More than 10</MenuItem>
                <MenuItem value={'Less than 20'}>Less than 20</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
                <MenuItem value={'Not aware'}>Not aware</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              How many youths in your local government area (LGA) are political
              parties executive at local, state, or national levels?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="civic13"
                id="civic13"
                name={'civic13'}
                fullWidth
                value={formik.values.civic13}
                onChange={formik.handleChange}
                error={formik.touched.civic13 && Boolean(formik.errors.civic13)}
                helperText={formik.touched.civic13 && formik.errors.civic13}
              >
                <MenuItem value={'Less than 5'}>Less than 5</MenuItem>
                <MenuItem value={'More than 5'}>More than 5</MenuItem>
                <MenuItem value={'More than 10'}>More than 10</MenuItem>
                <MenuItem value={'Less than 20'}>Less than 20</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
                <MenuItem value={'Not aware'}>Not aware</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Do you support the inclusion of women and the inclusion of Persons
              with Disability in elections?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="civic14"
                id="civic14"
                name={'civic14'}
                fullWidth
                value={formik.values.civic14}
                onChange={formik.handleChange}
                error={formik.touched.civic14 && Boolean(formik.errors.civic14)}
                helperText={formik.touched.civic14 && formik.errors.civic14}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            What skills do you need for community organising and personal
              development? Tick the correct answers
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="skill"
                id="skill"
                name={'skill'}
                multiple
                fullWidth
                value={formik.values.skill}
                onChange={formik.handleChange}
                input={<OutlinedInput label="What skills do you need for community organising and personal
                development? Tick the correct answers" />}
                renderValue={(selected) => selected.join(', ')}
              >
                {skillSet.map((skill) => (
                  <MenuItem key={skill.value} value={skill.value}>
                    <Checkbox checked={name.indexOf(skill.value) > -1} />
                    <ListItemText primary={skill.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'h6'} fontWeight={700}>
              Youth and Electoral Violence
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Has there been a case or cases of electoral violence in your
              community?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="violence1"
                id="violence1"
                name={'violence1'}
                fullWidth
                value={formik.values.violence1}
                onChange={formik.handleChange}
                error={
                  formik.touched.violence1 && Boolean(formik.errors.violence1)
                }
                helperText={formik.touched.violence1 && formik.errors.violence1}
              >
                <MenuItem value={'Yes'}>Yes </MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Has electoral violence prevented you from participating in past
              elections in your community?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="violence2"
                id="violence2"
                name={'violence2'}
                fullWidth
                value={formik.values.violence2}
                onChange={formik.handleChange}
                error={
                  formik.touched.violence2 && Boolean(formik.errors.violence2)
                }
                helperText={formik.touched.violence2 && formik.errors.violence2}
              >
                <MenuItem value={'Yes'}>Yes </MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'I don’t know'}>I don’t know</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              How often has electoral violence occurred in your community?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="violence3"
                id="violence3"
                name={'violence3'}
                fullWidth
                value={formik.values.violence3}
                onChange={formik.handleChange}
                error={
                  formik.touched.violence3 && Boolean(formik.errors.violence3)
                }
                helperText={formik.touched.violence3 && formik.errors.violence3}
              >
                <MenuItem value={'1-5'}>1-5 </MenuItem>
                <MenuItem value={'6-10'}>6-10</MenuItem>
                <MenuItem value={'11-15'}>11-15</MenuItem>
                <MenuItem value={'16 & above'}>16 & above</MenuItem>
                <MenuItem value={'Never'}>Never</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              To the best of your knowledge, who was responsible for past
              electoral violence in your community?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="violence4"
                id="violence4"
                name={'violence4'}
                fullWidth
                value={formik.values.violence4}
                onChange={formik.handleChange}
                error={
                  formik.touched.violence4 && Boolean(formik.errors.violence4)
                }
                helperText={formik.touched.violence4 && formik.errors.violence4}
              >
                <MenuItem value={'Security agents'}>Security agents</MenuItem>
                <MenuItem value={'Political thugs'}>Political thugs</MenuItem>
                <MenuItem value={'Party agents'}>Party agents</MenuItem>
                <MenuItem value={'Those sharing money'}>
                  Those sharing money
                </MenuItem>
                <MenuItem value={'I don’t know'}>
                I don’t know
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              How will you describe the age bracket of perpetrators of electoral
              violence in your area?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="violence5"
                id="violence5"
                name={'violence5'}
                fullWidth
                value={formik.values.violence5}
                onChange={formik.handleChange}
                error={
                  formik.touched.violence5 && Boolean(formik.errors.violence5)
                }
                helperText={formik.touched.violence5 && formik.errors.violence5}
              >
                <MenuItem value={'Below 18 years old'}>
                  Below 18 years old
                </MenuItem>
                <MenuItem value={'18-35 years old'}>18-35 years old</MenuItem>
                <MenuItem value={'36 years old & above'}>
                  36 years old & above
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              From the security indices in your community, would the 2023
              general elections be violence-free?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="violence6"
                id="violence6"
                name={'violence6'}
                fullWidth
                value={formik.values.violence6}
                onChange={formik.handleChange}
                error={
                  formik.touched.violence6 && Boolean(formik.errors.violence6)
                }
                helperText={formik.touched.violence6 && formik.errors.violence6}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'Yes, but we need security'}>
                  Yes, but we need security
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              How do you hear about us?
            </Typography>
            <FormControl required fullWidth>
              <Select
                labelId="medium"
                id="medium"
                name={'medium'}
                fullWidth
                value={formik.values.medium}
                onChange={formik.handleChange}
                error={formik.touched.medium && Boolean(formik.errors.medium)}
                helperText={formik.touched.medium && formik.errors.medium}
              >
                <MenuItem value={'Facebook'}>Facebook </MenuItem>
                <MenuItem value={'Instagram'}>Instagram </MenuItem>
                <MenuItem value={'Twitter'}>Twitter </MenuItem>
                <MenuItem value={'WhatsApp'}>WhatsApp </MenuItem>
                <MenuItem value={'LinkedIn'}>LinkedIn </MenuItem>
                <MenuItem value={'Website'}>Website</MenuItem>
                <MenuItem value={'Community Development Champion'}>
                  Community Development Champion
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item container xs={12}>
            <Box width={'100%'}>
              <ColorButton size={'large'} variant={'contained'} type={'submit'}>
                Submit
              </ColorButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
