import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Image from '../../../svg/work.jpg';

const HowWeWork = () => {
  return (
    <Box
      display="flex"
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      alignItems={'center'}
      marginTop={{ xs: '25%', sm: '10%' }}
    >
      <Box width={{ xs: '100%', sm: '50%' }}>
        <Box
          component="img"
          height={{ xs: '35vh', sm: '75vh' }}
          sx={{
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
          }}
          src={Image}
          alt={'about_img'}
        />
      </Box>
      <Box width={{ xs: '100%', sm: '50%' }} padding={'6%'} marginTop={{ xs: '25%', sm: '0%' }}>
        <Typography
          variant="h4"
          color="#81B214"
          fontWeight={700}
          paddingBottom={'5%'}
        >
          How We Work
        </Typography>
        <Typography variant="body1" color="#4f4f4f" paddingBottom={'3%'} data-aos={'fade-up'}>
        &#8284; GRAP platform development & community engagement
        </Typography>
        <Typography variant="body1" color="#4f4f4f" paddingBottom={'3%'} data-aos={'fade-up'}>
        &#8284; Community needs survey via GRAP platform & offline
        </Typography>
        <Typography variant="body1" color="#4f4f4f" paddingBottom={'3%'} data-aos={'fade-up'}>
        &#8284; Statistical analysis and visualization
        </Typography>
        <Typography variant="body1" color="#4f4f4f" paddingBottom={'3%'} data-aos={'fade-up'}>
        &#8284; Development of National Needs Assessment Report (NNAR)
        </Typography>
        <Typography variant="body1" color="#4f4f4f" paddingBottom={'3%'} data-aos={'fade-up'}>
        &#8284; Launch/presentation of report to government & stakeholders
        </Typography>
        <Typography variant="body1" color="#4f4f4f" paddingBottom={'3%'} data-aos={'fade-up'}>
        &#8284; High-level engagements with government MDAs & tracking of
          adoption into budget.
        </Typography>
      </Box>
    </Box>
  );
};

export default HowWeWork;
