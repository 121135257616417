import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import Header from './components/Header';
import AppBar from '@mui/material/AppBar';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import AddPost from './components/AddPost';
import PostPortal from './components/PostPortal';
import Education from './components/Education';
import { Dashboard } from './components';
import Agric from './components/Agric';
import Youth from './components/Youth';

const Admin = () => {
  const theme = useTheme();

  return (
    <Box>
      <AppBar
        position={'fixed'}
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
        elevation={1}
      >
        <Header />
      </AppBar>
      <Box display={'flex'} justifyContent={'space-between'} width={'98%'}>
        <Box
          width={{ xs: '0%', sm: '15%' }}
          borderRight={{ xs: 'none', sm: '1px solid #eee' }}
          height={'100vh'}
          marginTop={8}
          display={{xs: 'none', sm: 'inline'}} >
          <Sidebar />
        </Box>
        <Box
          width={{ xs: '95%', sm: '83%' }}
          marginTop={{ xs: '9%', sm: '0%' }}
        >
          <Switch>
            <Route exact path="/admin/" component={Dashboard} />
            <Route exact path="/admin/add-post" component={AddPost} />
            <Route exact path="/admin/post-portal" component={PostPortal} />
            <Route
              path="/admin/agric-portal"
              component={Agric}
            />
            <Route exact path="/admin/education-portal" component={Education} />
            <Route exact path="/admin/FCT-youth-portal" component={Youth} />
          </Switch>
        </Box>
      </Box>
      <Box bgcolor={'#81B214'}>
        <Container>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default Admin;
