import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Container from 'common/Container';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { IconButton } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const initialPost = {
  title: '',
  caption: '',
  category: '',
  date: '',
  author: '',
  paragraph1: '',
  paragraph2: '',
  paragraph3: '',
  paragraph4: '',
  paragraph5: '',
  paragraph6: '',
  paragraph7: '',
  paragraph8: '',
  image: '',
};

const BlogPost = () => {

  const [postDetail, setPostDetail] = useState(initialPost);

  const theme = useTheme();

  let { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://app-grap.herokuapp.com/api/post/${id}`)
      .then((res) => {
        setPostDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const handleShare = (e) => {
    e.preventDefault();

    const ahref = window.location.href;
    const encodedAhref = encodeURIComponent(ahref);
    var link;

    switch (e.currentTarget.id) {
      case 'facebook':
        link = `https://www.facebook.com/sharer/sharer.php?u=${ahref}`;
        open(link);
        break;

      case 'twitter':
        link = `https://twitter.com/intent/tweet?url=${encodedAhref}`;
        open(link);
        break;

      case 'linkedin':
        link = `https://www.linkedin.com/sharing/share-offsite/?url=${ahref}`;
        open(link);
        break;

      default:
        break;
    }
  };

  const open = (socialLink) => {
    window.open(socialLink, '_blank');
  };

  return (
    <Box>
      <AppBar
        position={'fixed'}
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
        elevation={1}
      >
        <Container paddingY={{ xs: 1 / 2, sm: 1 }}>
          <Header />
        </Container>
      </AppBar>
      <Box
        width={{ xs: '95%', sm: '50%' }}
        paddingTop={{ xs: '15%', sm: '5%' }}
      >
        <Container>
          <Box display={'flex'} alignItems={'center'} marginBottom={'5%'}>
            <img src={postDetail.image} alt="blog_img" width={'95%'} />
          </Box>
          <Typography
            variant="h5"
            color={'#81B214'}
            fontWeight={700}
            paddingBottom={'2%'}
            textAlign={'center'}
          >
            {postDetail.title}
          </Typography>
          <Box
            paddingBottom={'2%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexWrap={'wrap'}
            width={{ xs: '97%', sm: '65%' }}
            marginLeft={0}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              width={{ xs: '60%', sm: '40%' }}
              padding={{ xs: '5% 0%', sm: '0%' }}
            >
              <CalendarTodayIcon />
              <Typography variant="body1" color={'#000'}>
                {postDetail.date}
              </Typography>
            </Box>
            <Box>
              <Breadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon fontSize="small" />}>
                <Link
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="#F58634"
                  href="/"
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Home
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: 'flex', alignItems: 'center' }}
                  color="#F58634"
                  href="/blog"
                >
                  <WhatshotIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  Blog
                </Link>
              </Breadcrumbs>
            </Box>
          </Box>
          <Typography variant="body1" paddingBottom={{ xs: '5%', sm: '2%' }}>
            {postDetail.paragraph1}
          </Typography>
          <Typography variant="body1" paddingBottom={{ xs: '5%', sm: '2%' }}>
            {postDetail.paragraph2} 
          </Typography>
          <Typography variant="body1" paddingBottom={{ xs: '5%', sm: '2%' }}>
            {postDetail.paragraph3}
          </Typography>
          <Typography variant="body1" paddingBottom={{ xs: '5%', sm: '2%' }}>
            {postDetail.paragraph4}
          </Typography>
          <Typography variant="body1" paddingBottom={{ xs: '5%', sm: '2%' }}>
            {postDetail.paragraph5}
          </Typography>
          <Typography variant="body1" paddingBottom={{ xs: '5%', sm: '2%' }}>
            {postDetail.paragraph6}
          </Typography>
          <Typography variant="body1" paddingBottom={{ xs: '5%', sm: '2%' }}>
            {postDetail.paragraph7}
          </Typography>
          <Typography variant="body1" paddingBottom={{ xs: '5%', sm: '2%' }}>
            {postDetail.paragraph8}
          </Typography>
          <Typography variant="body2" fontWeight={700} color={'#F58634'}>
            {postDetail.author}
          </Typography>
          <Box marginTop={'5%'}  width={{ xs: '60%', sm: '45%' }} marginLeft={0}>
            <Typography variant="h6" fontWeight={500} color={'#81B214'}>
              Share this post
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                width={'40%'}
                marginLeft={0}
              >
                <IconButton id="facebook" onClick={handleShare} color="primary">
                  <FacebookIcon  />
                </IconButton>
                <IconButton id="twitter" onClick={handleShare} color="primary">
                  <TwitterIcon />
                </IconButton>
                <IconButton id="linkedin" onClick={handleShare} color="primary">
                  <LinkedInIcon />
                </IconButton>
              </Box>
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box bgcolor={'#81B214'}>
        <Container>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default BlogPost;