import React from 'react';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Logout from '@mui/icons-material/Logout';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import SchoolIcon from '@mui/icons-material/School';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

const Sidebar = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const logOut = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  return (
    <Box marginTop={'35%'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        alignItems={'left'}
      >
        <List component="nav" aria-label="main mailbox folders">
          <ListItemButton
            selected={selectedIndex === 0}
            onClick={(event) => handleListItemClick(event, 0)}
            component="a" to="/admin/" 
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={(event) => handleListItemClick(event, 1)}
            component="a" to="/admin/add-post" 
          >
            <ListItemIcon>
              <PostAddIcon />
            </ListItemIcon>
            <ListItemText primary="Add Post"/>
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={(event) => handleListItemClick(event, 2)}
            component="a" to="/admin/agric-portal" 
          >
            <ListItemIcon>
              <AgricultureIcon />
            </ListItemIcon>
            <ListItemText primary="Agric Portal" />
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 3}
            onClick={(event) => handleListItemClick(event, 3)}
            component="a" to="/admin/education-portal" 
          >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="Education Portal"/>
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 4}
            onClick={(event) => handleListItemClick(event, 4)}
            component="a" to="/admin/FCT-youth-portal" 
          >
            <ListItemIcon>
              <DirectionsRunIcon />
            </ListItemIcon>
            <ListItemText primary="FCT Youth Portal"/>
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 5}
            onClick={(event) => handleListItemClick(event, 5)}
            component="a" to="/admin/post-portal" 
          >
            <ListItemIcon>
              <LibraryBooksIcon />
            </ListItemIcon>
            <ListItemText primary="Post Portal" />
          </ListItemButton>
          <ListItemButton
            selected={selectedIndex === 3}
            onClick={logOut}
            component="a" 
          >
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout"/>
          </ListItemButton>
        </List>
      </Box>
    </Box>
  );
};

export default Sidebar;
