import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EducationList from './EducationList';
import DetailEducation from './DetailEducation';
import { axiosWithAuth } from 'utils/axiosWithAuth';

const Education = () => {
  const [educationList, setEducationList] = useState([]);

  useEffect(() => {
    axiosWithAuth()
      .get('/education')
      .then((res) => {
        setEducationList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <Box marginBottom={{ xs: '25%', sm: '12%' }}>
      <Box
        marginTop={{ xs: '25%', sm: '12%' }}
        paddingLeft={{ xs: '0%', sm: '1%' }}
      >
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            color="#81B214"
            sx={{
              fontWeight: 700,
            }}
          >
            Education NEEDS Assessment Portal
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 5, fontWeight: 700, color:'#F58634'}}>No</TableCell>
                <TableCell style={{ width: 200, fontWeight: 700,  color:'#F58634' }} align="left">
                  Email Address
                </TableCell>
                <TableCell style={{ width: 220, fontWeight: 700,  color:'#F58634' }} align="left">
                  Phone Number
                </TableCell>
                <TableCell style={{ width: 150, fontWeight: 700,  color:'#F58634' }} align="right">
                  Gender
                </TableCell>
                <TableCell style={{ width: 200, fontWeight: 700,  color:'#F58634' }} align="right">
                  Age
                </TableCell>
                <TableCell style={{ width: 150, fontWeight: 700,  color:'#F58634' }} align="left">
                  Education
                </TableCell>
                <TableCell style={{ width: 200, fontWeight: 700,  color:'#F58634' }} align="left">
                  State of Residence
                </TableCell>
                <TableCell style={{ width: 150, fontWeight: 700,  color:'#F58634' }} align="left">
                  City
                </TableCell>
                <TableCell style={{ width: 200, fontWeight: 700,  color:'#F58634' }} align="left">
                  State of Origin
                </TableCell>
                <TableCell style={{ width: 200, fontWeight: 700,  color:'#F58634' }} align="left">
                  City
                </TableCell>
                <TableCell style={{ width: 150, fontWeight: 700,  color:'#F58634' }} align="left">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Switch>
          <Route path="/admin/education-portal/view">
            <DetailEducation />
          </Route>
          <Route exact path="/admin/education-portal">
            <EducationList
              educationForms={educationList}
              updateEducation={setEducationList}
            />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default Education;
