import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DetailAgric = ({
  detail,
  handleClose,
  setDetail,
  agricDetail,
}) => {
  return (
    <Box margin={'2% 0%'} paddingLeft={{ xs: '0%', sm: '3%' }}>
      <Modal
        open={detail}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            <Box width={'45%'}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Email Address: {agricDetail.email}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Phone Number: {agricDetail.phone}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Gender: {agricDetail.gender}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Age: {agricDetail.age}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Education: {agricDetail.education}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Occupation: {agricDetail.occupation}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Disability: {agricDetail.disability}
              </Typography>
              <Typography
                variant={'subtitle2'}
                fontWeight={700}
                sx={{ marginBottom: 1 }}
              >
                Current State of Residence
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                State: {agricDetail.state}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                City: {agricDetail.city}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Local Govt: {agricDetail.lga}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Estimated Population: {agricDetail.population}
              </Typography>
              <Typography
                variant={'subtitle2'}
                fontWeight={700}
                sx={{ marginBottom: 1 }}
              >
                Original State of Origin
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                State: {agricDetail.state2}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                City: {agricDetail.city2}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Local Govt: {agricDetail.lga2}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Estimated Population: {agricDetail.population2}
              </Typography>
              <Typography
                variant={'subtitle2'}
                fontWeight={700}
                sx={{ marginBottom: 1 }}
              >
                Fact Finding on Problems
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 1: {agricDetail.agric1}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 2: {agricDetail.agric2}
              </Typography>
            </Box>
            <Box width={'45%'}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 3: {agricDetail.agric3}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 4: {agricDetail.agric4}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 5: {agricDetail.agric5}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 6: {agricDetail.agric6}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 7: {agricDetail.agric7}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 8: {agricDetail.agric8}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 9: {agricDetail.agric9}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 10: {agricDetail.agric10}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 11: {agricDetail.agric11}
              </Typography>
              <Typography
                variant={'subtitle2'}
                fontWeight={700}
                sx={{ marginBottom: 1 }}
              >
               Proposed Solutions for Budget Consideration
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 1: {(agricDetail.option1).toString()}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 2: {(agricDetail.option2).toString()}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 3: {(agricDetail.option3).toString()}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 4: {(agricDetail.option4).toString()}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 5: {(agricDetail.option5).toString()}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 6: {(agricDetail.option6).toString()}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 7: {(agricDetail.option8).toString()}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 9: {(agricDetail.option9).toString()}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 10: {(agricDetail.option10).toString()}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
                Option 11: {(agricDetail.option11).toString()}
              </Typography>
            </Box>
          </Box>
          <Box margin={'2%'}>
            <Button
              color={'error'}
              variant={'contained'}
              onClick={() => setDetail(false)}
            >
              cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DetailAgric;
