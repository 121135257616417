import React from 'react';
import Box from '@mui/material/Box';
import { useTheme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import logo from 'svg/logos/logo.png';

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const view = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ColorButton = styled(Button)(() => ({
    color: '#fff',
    width: 125,
    height: 45,
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'capitalize',
    backgroundColor: '#F58634',
    borderColor: '#F58634',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#81B214',
      borderColor: '#81B214',
    },
  }));

  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      marginBottom={{ xs: 2, sm: 1 }}
    >
      <Box
        sx={{ display: { xs: 'none', md: 'flex' } }}
        marginRight={{ xs: '0%', sm: '15%' }}
        paddingLeft={{ xs: '0%', sm: '2%' }}
        width={230}
      >
        <Box component="a" underline="none" href="/" title="GRAP">
          <img src={logo} alt="logo" width={'60%'} />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems={'center'}
        width={{ xs: '100%', sm: '100%' }}
        justifyContent={'space-between'}
      >
        <Box
          sx={{ display: { xs: 'none', md: 'flex' } }}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
          fontWeight={600}
          color={'#81B214'}
        >
          <Box>
            <Link underline="none" component="a" href="/" color={'#81B214'}>
              Home
            </Link>
          </Box>
          <Box>
            <Link
              underline="none"
              component="a"
              href="/aboutus"
              color="#81B214"
            >
              About Us
            </Link>
          </Box>
          <Box>
            <Link
              id="fade-button"
              aria-controls={view ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={view ? 'true' : undefined}
              onClick={handleClick2}
              underline="none"
              color={'#81B214'}
            >
              NEEDS Survey
            </Link>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl2}
              open={view}
              onClose={handleClose2}
              TransitionComponent={Fade}
            >
              <MenuItem
                onClick={handleClose2}
                component="a"
                href="/NEEDS-assessment-survey-agriculture"
                color={'#81B214'}
              >
                Agric & Rural Development
              </MenuItem>
              <MenuItem
                onClick={handleClose2}
                component="a"
                href="/NEEDS-assessment-survey-education"
                color={'#81B214'}
              >
                Education
              </MenuItem>
              <MenuItem
                onClick={handleClose2}
                component="a"
                href="/FCT-Youth-Needs-Assessment-on-Civic-Participation"
                color={'#81B214'}
              >
                FCT Youth
              </MenuItem>
            </Menu>
          </Box>
          <Box>
            <Link
              underline="none"
              component="a"
              href="/resources/infographics"
              color="#81B214"
            >
              Resources
            </Link>
          </Box>
          <Box>
            <Link underline="none" component="a" href="/blog" color={'#81B214'}>
              Blog
            </Link>
          </Box>
          <Box>
            <ColorButton
              component={'a'}
              variant="outlined"
              size="medium"
              href={' https://flutterwave.com/donate/57wfprqw0duo'}
              target={'_blank'}
            >
              Donate
            </ColorButton>
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }} width={'100%'}>
          <AppBar
            position="fixed"
            zIndex={theme.zIndex.drawer + 1}
            sx={{
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Toolbar>
              <Box
                component="a"
                underline="none"
                href="/"
                title="GRAP"
                width={95}
                marginRight={25}
                marginTop={1}
                marginBottom={1}
              >
                <Box
                  component="img"
                  height={'8vh'}
                  sx={{
                    display: 'block',
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                  src={logo}
                  alt={'logo'}
                />
              </Box>
              <IconButton
                aria-label="more"
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon fontSize="large" sx={{ color: '#81B214' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleClose} component="a" href="/">
              Home
            </MenuItem>
            <MenuItem onClick={handleClose} component="a" href="/aboutus">
              About Us
            </MenuItem>
            <Box padding={'7% 13%'}>
              <Link
                id="fade-button"
                aria-controls={view ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={view ? 'true' : undefined}
                onClick={handleClick2}
                underline="none"
                color={'#4f4f4f'}
              >
                NEEDS
              </Link>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl2}
                open={view}
                onClose={handleClose2}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={handleClose2}
                  component="a"
                  href="/NEEDS-assessment-survey-agriculture"
                  color={'#81B214'}
                >
                  Agric & Rural Development
                </MenuItem>
                <MenuItem
                  onClick={handleClose2}
                  component="a"
                  href="/NEEDS-assessment-survey-education"
                  color={'#81B214'}
                >
                  Education
                </MenuItem>
                <MenuItem
                  onClick={handleClose2}
                  component="a"
                  href="/FCT-Youth-Needs-Assessment-on-Civic-Participation"
                  color={'#81B214'}
                >
                  FCT Youth
                </MenuItem>
              </Menu>
            </Box>
            <MenuItem
              onClick={handleClose}
              component="a"
              href="/resources/infographics"
            >
              Resources
            </MenuItem>
            <MenuItem onClick={handleClose} component="a" href="/blog">
              Blog
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
