import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TwitterFeeds from './TwitterFeeds';

const ColorButton = styled(Button)(() => ({
  color: '#fff',
  width: 250,
  height: 55,
  fontWeight: 600,
  fontSize: '18px',
  textTransform: 'capitalize',
  backgroundColor: '#F58634',
  borderColor: '#F58634',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#81B214',
    borderColor: '#81B214',
  },
}));

const Join = () => {
  return (
    <Grid container paddingTop={{ xs: '20%', sm: '10%' }} paddingBottom={{ xs: '10%', sm: '6%' }}>
      <Grid item xs={12} md={6.5} paddingLeft={{xs: '0%', sm: '5%'}}>
        <Box>
          <Typography variant={'h4'} fontWeight={700} paddingBottom={'3%'} color={'#81B214'}>
          Community NEEDS Assessment
          </Typography>
          <Typography variant={'body1'} paddingBottom={'3%'} color={'#4f4f4f'}>
          Join other active Nigerians to supply the needs of your community for the production of the National Needs Assessment Report (NNAR).
          </Typography>
          <ColorButton
            component={'a'}
            variant="outlined"
            color="primary"
            size="medium"
            href={'/NEEDS-assessment-survey'}
            endIcon={
              <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#fff"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            }
          >
            Get involved
          </ColorButton>
        </Box>
      </Grid>
      <TwitterFeeds />
    </Grid>
  );
};

export default Join;
