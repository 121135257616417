import React from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { axiosWithAuth } from 'utils/axiosWithAuth';

const validationSchema = yup.object({
  title: yup.string('Write your title').required('Please write your title'),
  category: yup
    .string('Select your article category')
    .required('Please specify your category'),
  date: yup
    .string('Enter your publication date')
    .required('Please specify your publication date'),
  author: yup
    .string('Enter your article author')
    .required('Please specify your article author'),
  image: yup
    .string('Enter your image link')
    .required('Please specify your image link'),
  caption: yup
    .string('Write your article introduction')
    .required('Please write your article introduction')
    .max(250, 'The article introduction should have max of 250 characters'),
});

const AddPost = () => {
  const { push } = useHistory();
  const initialValues = {
    title: '',
    category: '',
    date: '',
    author: '',
    image: '',
    caption: '',
    paragraph1: '',
    paragraph2: '',
    paragraph3: '',
    paragraph4: '',
    paragraph5: '',
    paragraph6: '',
    paragraph7: '',
    paragraph8: '',
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const newPost = {
      title: formik.values.title.trim(),
      category: formik.values.category.trim(),
      date: formik.values.date.trim(),
      author: formik.values.author.trim(),
      image: formik.values.image.trim(),
      caption: formik.values.caption.trim(),
      paragraph1: formik.values.paragraph1.trim(),
      paragraph2: formik.values.paragraph2.trim(),
      paragraph3: formik.values.paragraph3.trim(),
      paragraph4: formik.values.paragraph4.trim(),
      paragraph5: formik.values.paragraph5.trim(),
      paragraph6: formik.values.paragraph6.trim(),
      paragraph7: formik.values.paragraph7.trim(),
      paragraph8: formik.values.paragraph8.trim(),
    };

    axiosWithAuth()
      .post('/posts', newPost)
      .then((res) => {
        console.log(res);
        push('/admin/');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ColorButton = styled(Button)(() => ({
    color: '#fff',
    width: 250,
    height: 55,
    fontWeight: 600,
    fontSize: '18px',
    textTransform: 'capitalize',
    backgroundColor: '#F58634',
    borderColor: '#F58634',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#81B214',
      borderColor: '#81B214',
    },
  }));

  return (
    <Box margin={{xs:'25% 0%', sm:'12% 0%'}} paddingLeft={{xs:'0%', sm:'3%'}}>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          color="#81B214"
          sx={{
            fontWeight: 700,
          }}
        >
          Add a New Blogpost
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Title
            </Typography>
            <TextField
              label="Title"
              variant="outlined"
              name={'title'}
              fullWidth
              required
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Image Link
            </Typography>
            <TextField
              label="Image Link"
              variant="outlined"
              required
              name={'image'}
              fullWidth
              value={formik.values.image}
              onChange={formik.handleChange}
              error={formik.touched.image && Boolean(formik.errors.image)}
              helperText={formik.touched.image && formik.errors.image}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Introduction
            </Typography>
            <TextField
              label="Article Introduction"
              variant="outlined"
              name={'caption'}
              fullWidth
              required
              multiline
              rows={4}
              value={formik.values.caption}
              onChange={formik.handleChange}
              error={formik.touched.caption && Boolean(formik.errors.caption)}
              helperText={formik.touched.caption && formik.errors.caption}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            Paragraph One
            </Typography>
            <TextField
              label="Paragraph One"
              variant="outlined"
              name={'paragraph1'}
              required
              fullWidth
              multiline
              rows={4}
              value={formik.values.paragraph1}
              onChange={formik.handleChange}
              error={formik.touched.paragraph1 && Boolean(formik.errors.paragraph1)}
              helperText={formik.touched.paragraph1 && formik.errors.paragraph1}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            Paragraph Two
            </Typography>
            <TextField
              label="Paragraph Two"
              variant="outlined"
              name={'paragraph2'}
              required
              fullWidth
              multiline
              rows={4}
              value={formik.values.paragraph2}
              onChange={formik.handleChange}
              error={formik.touched.paragraph2 && Boolean(formik.errors.paragraph2)}
              helperText={formik.touched.paragraph2 && formik.errors.paragraph2}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            Paragraph Three
            </Typography>
            <TextField
              label="Paragraph Three"
              variant="outlined"
              name={'paragraph3'}
              fullWidth
              multiline
              rows={4}
              value={formik.values.paragraph3}
              onChange={formik.handleChange}
              error={formik.touched.paragraph3 && Boolean(formik.errors.paragraph3)}
              helperText={formik.touched.paragraph3 && formik.errors.paragraph3}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            Paragraph Four
            </Typography>
            <TextField
              label="Paragraph Four"
              variant="outlined"
              name={'paragraph4'}
              fullWidth
              multiline
              rows={4}
              value={formik.values.paragraph4}
              onChange={formik.handleChange}
              error={formik.touched.paragraph4 && Boolean(formik.errors.paragraph4)}
              helperText={formik.touched.paragraph4 && formik.errors.paragraph4}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            Paragraph Five
            </Typography>
            <TextField
              label="Paragraph Five"
              variant="outlined"
              name={'paragraph5'}
              fullWidth
              multiline
              rows={4}
              value={formik.values.paragraph5}
              onChange={formik.handleChange}
              error={formik.touched.paragraph5 && Boolean(formik.errors.paragraph5)}
              helperText={formik.touched.paragraph5 && formik.errors.paragraph5}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            Paragraph Six
            </Typography>
            <TextField
              label="Paragraph Six"
              variant="outlined"
              name={'paragraph6'}
              fullWidth
              multiline
              rows={4}
              value={formik.values.paragraph6}
              onChange={formik.handleChange}
              error={formik.touched.paragraph6 && Boolean(formik.errors.paragraph6)}
              helperText={formik.touched.paragraph6 && formik.errors.paragraph6}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            Paragraph Seven
            </Typography>
            <TextField
              label="Paragraph Seven"
              variant="outlined"
              name={'paragraph7'}
              fullWidth
              multiline
              rows={4}
              value={formik.values.paragraph7}
              onChange={formik.handleChange}
              error={formik.touched.paragraph7 && Boolean(formik.errors.paragraph7)}
              helperText={formik.touched.paragraph7 && formik.errors.paragraph7}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
            Paragraph Eight
            </Typography>
            <TextField
              label="Paragraph Eight"
              variant="outlined"
              name={'paragraph8'}
              fullWidth
              multiline
              rows={4}
              value={formik.values.paragraph8}
              onChange={formik.handleChange}
              error={formik.touched.paragraph8 && Boolean(formik.errors.paragraph8)}
              helperText={formik.touched.paragraph8 && formik.errors.paragraph8}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Select Category
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="state">Category</InputLabel>
              <Select
                labelId="category"
                id="category"
                label="Category"
                name={'category'}
                required
                fullWidth
                value={formik.values.category}
                onChange={formik.handleChange}
                error={
                  formik.touched.category && Boolean(formik.errors.category)
                }
                helperText={formik.touched.category && formik.errors.category}
              >
                <MenuItem value={'Blogpost'}>Blogpost</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Date of Publication
            </Typography>
            <TextField
              label="July 23, 2022"
              variant="outlined"
              required
              name={'date'}
              fullWidth
              value={formik.values.date}
              onChange={formik.handleChange}
              error={formik.touched.date && Boolean(formik.errors.date)}
              helperText={formik.touched.date && formik.errors.date}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Author
            </Typography>
            <TextField
              label="Author"
              variant="outlined"
              required
              name={'author'}
              fullWidth
              value={formik.values.author}
              onChange={formik.handleChange}
              error={formik.touched.author && Boolean(formik.errors.author)}
              helperText={formik.touched.author && formik.errors.author}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              width={'100%'}
            >
              <ColorButton size={'large'} variant={'contained'} type={'submit'}>
                Add Your Post
              </ColorButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default AddPost;
