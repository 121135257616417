import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';

const Footer = () => {
  return (
    <Grid container spacing={1} marginTop={1}>
      <Grid item xs={12}>
        <Divider color={'#fff'} />
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="#fff"
          marginTop={'1%'}
        >
          &copy;GRAP 2022. All rights reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
