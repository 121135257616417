import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Divider } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import logo from 'svg/logos/logo2.png';
import promad from 'svg/logos/promad.jpg';

const Footer = () => {
  return (
    <Grid container spacing={1} marginTop={5}>
      <Grid item xs={12} sm={6} md={12} marginBottom={2}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          width={{ xs: '100%', sm: '100%' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display="flex"
            flexWrap={'wrap'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <Box
              display={'flex'}
              component="a"
              underline="none"
              href="/"
              title="GRAP"
              width={150}
            >
              <img src={logo} alt="logo" width={'80%'} />
            </Box>
            <Box
              display="flex"
              flexWrap={'wrap'}
              justifyContent={'space-between'}
              flexDirection={{ xs: 'row', sm: 'row' }}
              width={{ xs: '100%', sm: '80%' }}
              marginTop={'5%'}
              color={'#1F4690'}
            >
              <Box
                display={'flex'}
                component="a"
                underline="none"
                href="https://twitter.com/grapngr"
                title="Twitter"
              >
                <Avatar sx={{ bgcolor: blue[300], width: 36, height: 36 }}>
                  <TwitterIcon />
                </Avatar>
              </Box>
              <Box
                display={'flex'}
                component="a"
                underline="none"
                href="https://www.facebook.com/grapngr"
                title="Facebook"
              >
                <Avatar sx={{ bgcolor: blue[800], width: 36, height: 36 }}>
                  <FacebookIcon />
                </Avatar>
              </Box>
              <Box
                display={'flex'}
                component="a"
                underline="none"
                href="https://www.linkedin.com/company/"
                title="LinkedIn"
              >
                <Avatar sx={{ bgcolor: blue[700], width: 36, height: 36 }}>
                  <LinkedInIcon />
                </Avatar>
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexWrap={'wrap'}
            justifyContent={'space-between'}
            width={{ xs: '95%', sm: '100%' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
            marginLeft={{ xs: '0%', sm: '5%' }}
            marginBottom={2}
          >
            <Box
              display="flex"
              flexWrap={'wrap'}
              flexDirection={'column'}
              marginTop={{ xs: '10%', sm: '0%' }}
              width={{ xs: '90%', sm: '20%' }}
            >
              <Typography
                sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
                gutterBottom
                color={'#fff'}
                align={'left'}
              >
                Company
              </Typography>
              <Link
                underline="none"
                component="a"
                href="/aboutus"
                color="#fff"
                variant={'subtitle2'}
                lineHeight={2}
              >
                About
              </Link>
              <Link
                underline="none"
                component="a"
                href="/"
                color="#fff"
                variant={'subtitle2'}
                lineHeight={2}
              >
                Our Team
              </Link>
              <Link
                underline="none"
                component="a"
                href="/"
                color="#fff"
                variant={'subtitle2'}
                lineHeight={2}
              >
                Careers
              </Link>
              <Link
                underline="none"
                component="a"
                href=" https://flutterwave.com/donate/57wfprqw0duo"
                color="#fff"
                target={'_blank'}
                variant={'subtitle2'}
                lineHeight={2}
              >
                Donate
              </Link>
            </Box>
            <Box
              display="flex"
              flexWrap={'wrap'}
              flexDirection={'column'}
              marginTop={{ xs: '10%', sm: '0%' }}
              width={{ xs: '90%', sm: '25%' }}
            >
              <Typography
                sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
                gutterBottom
                color={'#fff'}
                align={'left'}
              >
                Contact
              </Typography>
              <Typography
                underline="none"
                component="a"
                color="#fff"
                variant={'subtitle2'}
                lineHeight={2}
              >
                <PhoneIcon  sx={{ width: 12, height: 12 }}/> +234 818 017 7099
              </Typography>
              <Typography
                underline="none"
                component="a"
                color="#fff"
                variant={'subtitle2'}
                lineHeight={2}
              >
                <EmailIcon sx={{ width: 12, height: 12 }}/> grap@promadfoundation.org
              </Typography>
              <Typography
                underline="none"
                component="a"
                color="#fff"
                variant={'subtitle2'}
                lineHeight={2}
              >
                <LocationOnIcon sx={{ width: 12, height: 12 }}/>Suite D-303, Second Floor, Soar Plaza, 1st Avenue, Gwarinpa,
                Abuja, Nigeria
              </Typography>
            </Box>
            <Box
              display="flex"
              flexWrap={'wrap'}
              flexDirection={'column'}
              marginTop={{ xs: '10%', sm: '0%' }}
              width={{ xs: '90%', sm: '25%' }}
            >
              <Typography
                sx={{ textTransform: 'uppercase', fontWeight: 'medium' }}
                gutterBottom
                color={'#fff'}
                align={'left'}
              >
                About
              </Typography>
              <Typography
                underline="none"
                component="a"
                color="#fff"
                variant={'subtitle2'}
                lineHeight={2}
              >
                GRAP is a civic-tech initiative for budget participation by
                PROMAD—using tech to document the NEEDS of Nigerian communities
                for adoption into the annual budget.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider color={'#fff'} />
        <Box
          display="flex"
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'wrap'}
          width={'100%'}
          marginTop={'2%'}
        >
          <Box width={{xs:'100%', sm:'70%'}}>
            <Typography align={'left'} variant={'subtitle2'} color="#fff">
              &copy; {new Date().getFullYear()} PROMAD. All rights reserved
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems={'center'}
            flexWrap={'wrap'}
            paddingTop={{xs:'5%', sm: '0%'}}
            justifyContent={'space-between'}
            width={{xs:'60%', sm:'20%'}}
          >
            <Typography align={'left'} variant={'subtitle2'} fontWeight={700} color="#fff">
              Powered By:
            </Typography>
            <Box
              component="a"
              underline="none"
              href="https://promadfoundation.org/"
              title="Promad Logo"
              target={'_blank'}
              width={{xs: '30%', sm: '50%'}}
            >
              <img src={promad} alt={'Promad Foundation'} width={'100%'} height={'100%'} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          paddingTop={{xs:'1%', sm: '0%'}}
          color="#ddd"
        >
          Made with ❤️ by <Link underline="always" component="a" href="https://oluseye.vercel.app/" color={'#fff'} target={'_blank'}>
          Oluseye</Link>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
