import React from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';

const BlogCard = ({ post }) => {
  const history = useHistory();
  const viewPost = () => {
    history.push(`blog/${post.post_id}`);
  };

  return (
    <Grid item xs={12} md={4} marginTop={{ xs: '10%', sm: '2%' }}>
      <Card sx={{ maxWidth: 380, height: 520 }}>
        <CardActionArea component={'a'} onClick={viewPost}>
          <CardMedia
            component="img"
            height="250"
            image={post.image}
            alt="blog"
          />
          <CardContent>
            <Typography
              fontWeight={500}
              variant="body2"
              color={'#aaa'}
              padding={'2% 0%'}
            >
              {post.date}
            </Typography>
            <Typography
              gutterBottom
              fontWeight={700}
              variant="h6"
              component="div"
              paddingBottom={'1%'}
              color={'#81B214'}
            >
              {post.title}
            </Typography>
            <Typography fontWeight={500} variant="body2" color={'#4f4f4f'}>
              {post.caption}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default BlogCard;
