import React, { useState } from 'react';
import {  useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import DetailPost from './DetailPost';
import ThePost from './ThePost';
import { axiosWithAuth } from 'utils/axiosWithAuth';

const initialPost = {
  title: '',
  caption: '',
  article: '',
  category: '',
  date: '',
  author: '',
};

const PostList = ({ posts }) => {
  const [detail, setDetail] = useState(false);
  const [postDetail, setPostDetail] = useState(initialPost);
  const [delPost, setDelPost] = useState(false);

  const { push } = useHistory();

  const detailPost = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .get(`/post/${postDetail.post_id}`)
      .then((res) => {
        setPostDetail(res.data);
        push('/admin/');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewPost = (posts) => {
    setDetail(true);
    setPostDetail(posts);
  };


  const deletePost = (post) => {
    axiosWithAuth()
      .delete(`/post/${post.post_id}`)
      .then((res) => {
        setDelPost(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <Box>
        {posts.map((post) => (
          <ThePost
            key={post.id}
            post={post}
            deletePost={deletePost}
            viewPost={viewPost}
            delPost={delPost}
          />
        ))}
        {detail && (
          <DetailPost
            detailPost={detailPost}
            setDetail={setDetail}
            postDetail={postDetail}
            setPostDetail={setPostDetail}
          />
        )}
      </Box>
    </Box>
  );
};

export default PostList;
