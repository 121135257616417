import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import Header from 'components/Header';
import AppBar from '@mui/material/AppBar';
import Footer from 'components/Footer';
import Form from './components/Form';

const Agric = () => {
  const theme = useTheme();

  return (
    <Box>
      <AppBar
        position={'fixed'}
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
        elevation={1}
      >
        <Container paddingY={{ xs: 1 / 2, sm: 1/2 }}>
          <Header />
        </Container>
      </AppBar>
      <Box>
        <Container>
          <Form />
        </Container>
      </Box>
      <Box bgcolor={'#81B214'}>
        <Container>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default Agric;
