import * as yup from 'yup';

export const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .trim()
    .email('Please enter a valid email address'),
  gender: yup
    .string('Select your gender ')
    .trim()
    .required('Please specify your gender'),
  age: yup
    .string('Select your age range')
    .trim()
    .required('Please specify your age range'),
  education: yup
    .string('Select your Education Qualification')
    .trim()
    .required('Please specify your Education Qualification'),
  occupation: yup
    .string('Enter your occupation')
    .trim()
    .required('Please specify your occupation'),
  lga: yup
    .string('Select your local govt of residence')
    .trim()
    .required('Please specify your local govt of residence'),
  community: yup
    .string('Select your community')
    .trim()
    .required('Please specify your community'),
  others: yup
    .string('Please state your community')
    .trim(),
  income: yup
    .string('Select your source of income')
    .trim()
    .required('Please specify your source of income'),
  priority: yup
    .string('Select your important priorities')
    .required('Please specify your important priorities'),
  civic1: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic2: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic3: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic4: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic5: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic6: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic7: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic8: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic9: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic10: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic11: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic12: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic13: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  civic14: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  skill: yup
    .string('Select your option')
    .required('Please specify your option'),
  violence1: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  violence2: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  violence3: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  violence4: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  violence5: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  violence6: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  medium: yup
    .string('Select how you heard about us')
    .trim()
    .required('Please specify your option'),
});
