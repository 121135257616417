import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReactGA from 'react-ga';
import Home from 'components/Home';
import About from 'components/About';
import Agric from 'components/Agric';
import Education from 'components/Education';
import Resources from 'components/Resources';
import Blog from 'components/Blog';
import Admin from 'components/Admin';
import Login from 'components/Login';
import BlogPost from 'components/Blog/components/BlogPost';
import Needs from 'components/Needs';
import PrivateRoute from 'utils/PrivateRoute';
import Youth from 'components/Youth';

const TRACKING_ID = 'G-VF8QRZ8DKS';

ReactGA.initialize(TRACKING_ID);

const theme = createTheme({
  typography: {
    fontFamily: {
      fontFamily: 'Inter',
    },
  },
});

function App() {
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute path="/admin/" component={Admin} />
          <Route exact path="/aboutus" component={About} />
          <Route exact path="/NEEDS-assessment-survey" component={Needs} />
          <Route
            exact
            path="/NEEDS-assessment-survey-agriculture"
            component={Agric}
          />
          <Route
            exact
            path="/NEEDS-assessment-survey-education"
            component={Education}
          />
          <Route
            exact
            path="/FCT-Youth-Needs-Assessment-on-Civic-Participation"
            component={Youth}
          />
          <Route path="/resources" component={Resources} />
          <Route exact path="/blog" component={Blog} />
          <Route path="/blog/:id" component={BlogPost} />
          <Route exact path="/" component={Home} />
        </Switch>
      </div>
    </ThemeProvider>
  );
}

export default App;
