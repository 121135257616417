import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from 'svg/logos/logo.png';

const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  const theme = useTheme();

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={'100%'}
      marginBottom={{ xs: 2, sm: 1 }}
      marginTop={{ xs: 2, sm: 1 }}
    >
      <Box
        sx={{ display: { xs: 'none', md: 'flex' } }}
        marginRight={{ xs: '0%', sm: '15%' }}
        paddingLeft={{ xs: '0%', sm: '2%' }}
        width={230}
      >
        <Box component="a" underline="none" href="/admin/" title="GRAP">
          <img src={logo} alt="logo" width={'60%'} />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems={'center'}
        width={{ xs: '100%', sm: '90%' }}
        justifyContent={'space-between'}
      >
        <Box
          sx={{ display: { xs: 'none', md: 'flex' } }}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
          paddingLeft={'20%'}
          fontWeight={700}
          color={'#004165'}
        ></Box>
        <Box sx={{ display: { xs: 'flex', md: 'none' } }} width={'100%'}>
          <AppBar
            position="fixed"
            zIndex={theme.zIndex.drawer + 1}
            sx={{
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Toolbar>
              <Box
                component="a"
                underline="none"
                href="/admin/"
                title="GRAP"
                width={90}
                marginRight={25}
                marginTop={1}
              >
                <img src={logo} alt="logo" width={'75%'} />
              </Box>
              <IconButton
                aria-label="more"
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon fontSize="large" sx={{ color: '#81B214' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={handleClose} component="a" href="/admin/">
              Dashboard
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component="a"
              href="/admin/add-post"
            >
              Add Post
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component="a"
              href="/admin/agric-portal"
            >
              Agric Portal
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component="a"
              href="/admin/education-portal"
            >
              Education Portal
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component="a"
              href="/admin/FCT-youth-portal"
            >
              FCT Youth Portal
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              component="a"
              href="/admin/post-portal"
            >
             Post Portal
            </MenuItem>
            <MenuItem onClick={logOut} component="a">
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
