import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import budgit from 'svg/logos/budgit.png';
import civic from 'svg/logos/civic.png';
import leap from 'svg/logos/leap.png';
import ford from 'svg/logos/ford.png';
import macarthur from 'svg/logos/macarthur.png';
import nyff from 'svg/logos/nyff.png';

const Partners = () => {
  return (
    <Box padding={{xs:'25% 0%', sm:'5% 0% 10% 0%'}} width={'100%'}>
      <Typography
        variant="h4"
        color="#81B214"
        fontWeight={700}
        paddingBottom={'4%'}
        textAlign={'center'}
      >
        Our Partners
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexWrap={'wrap'}
        width={{xs:'100%', sm:'80%'}}
      >
        <Box
          component="img"
          height={{ xs: '9vh', sm: '7vh' }}
          width={{xs: '45%', sm:'12%'}}
          data-aos={'fade-up'}
          sx={{
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
          }}
          src={budgit}
          alt={'budgit_logo'}
        />
        <Box
          component="img"
          height={{ xs: '4vh', sm: '4vh' }}
          width={{xs: '45%', sm:'12%'}}
          marginLeft={{xs: '0%', sm:'2%'}}
          data-aos={'fade-up'}
          sx={{
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
          }}
          src={civic}
          alt={'civicHive_logo'}
        />
        <Box
          component="img"
          height={{ xs: '10vh', sm: '10vh' }}
          width={{xs: '45%', sm:'12%'}}
          data-aos={'fade-up'}
          sx={{
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
          }}
          src={leap}
          alt={'leap_logo'}
        />
        <Box
          component="img"
          height={{ xs: '10vh', sm: '10vh' }}
          width={{xs: '45%', sm:'12%'}}
          data-aos={'fade-up'}
          sx={{
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
          }}
          src={nyff}
          alt={'nyff_logo'}
        />
        <Box
          component="img"
          height={{ xs: '8vh', sm: '7vh' }}
          width={{xs: '45%', sm:'12%'}}
          data-aos={'fade-up'}
          sx={{
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
          }}
          src={ford}
          alt={'ford_logo'}
        />
        <Box
          component="img"
          height={{ xs: '10vh', sm: '9vh' }}
          width={{xs: '45%', sm:'12%'}}
          data-aos={'fade-up'}
          sx={{
            display: 'block',
            position: 'relative',
            overflow: 'hidden',
          }}
          src={macarthur}
          alt={'mac_logo'}
        />
      </Box>
    </Box>
  );
};

export default Partners;
