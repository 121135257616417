import React from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {localGovt} from '../../Agric/components/data';
import Autocomplete from '@mui/material/Autocomplete';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .trim()
    .email('Please enter a valid email address'),
  phone: yup
    .string('Enter your phone number')
    .trim()
    .min(11, 'Please enter a valid phone number')
    .required('Please specify your phone number'),
  gender: yup
    .string('Select your gender ')
    .trim()
    .required('Please specify your gender'),
  age: yup
    .string('Select your age range')
    .trim()
    .required('Please specify your age range'),
  education: yup
    .string('Select your Education Qualification')
    .trim()
    .required('Please specify your Education Qualification'),
  occupation: yup
    .string('Enter your occupation')
    .trim()
    .required('Please specify your occupation'),
  disability: yup
    .string('Select your disability option')
    .trim()
    .required('Please specify your disability option'),
  state: yup
    .string('Select your state of residence')
    .trim()
    .required('Please specify your state of residence'),
  city: yup
    .string('Enter your city of residence')
    .trim()
    .required('Please specify your city of residence'),
  lga: yup
    .string('Select your local govt of residence')
    .trim()
    .required('Please specify your local govt of residence'),
  population: yup
    .string('Enter your population of residence')
    .trim()
    .required('Please specify the population of residence'),
  state2: yup
    .string('Select your state of origin')
    .trim()
    .required('Please specify your state of origin'),
  city2: yup
    .string('Enter your city of origin')
    .trim()
    .required('Please specify your city of origin'),
  lga2: yup
    .string('Select your local govt of origin')
    .trim()
    .required('Please specify your local govt of origin'),
  population2: yup
    .string('Enter your population of origin')
    .trim()
    .required('Please specify the population of origin'),
  edu1: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  edu2: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
  edu3: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  edu4: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
  edu5: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  edu6: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
  edu7: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  edu8: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
  edu9: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  edu10: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
});

const Form = () => {
  const { push } = useHistory();
  const initialValues = {
    email: '',
    phone: '',
    gender: '',
    age: '',
    education: '',
    occupation: '',
    disability: '',
    city: '',
    lga: '',
    population: '',
    city2: '',
    lga2: '',
    population2: '',
    state: '',
    state2: '',
    edu1: '',
    edu2: '',
    edu3: '',
    edu4: '',
    edu5: '',
    edu6: '',
    edu7: '',
    edu8: '',
    edu9: '',
    edu10: '',
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const newRequest = {
      email: formik.values.email,
      phone: formik.values.phone,
      gender: formik.values.gender,
      age: formik.values.age,
      education: formik.values.education,
      occupation: formik.values.occupation,
      disability: formik.values.disability,
      state: formik.values.state,
      city: formik.values.city,
      lga: formik.values.lga,
      population: formik.values.population,
      state2: formik.values.state2,
      city2: formik.values.city2,
      lga2: formik.values.lga2,
      population2: formik.values.population2,
      edu1: formik.values.edu1,
      edu2: formik.values.edu2,
      edu3: formik.values.edu3,
      edu4: formik.values.edu4,
      edu5: formik.values.edu5,
      edu6: formik.values.edu6,
      edu7: formik.values.edu7,
      edu8: formik.values.edu8,
      edu9: formik.values.edu9,
      edu10: formik.values.edu10,
    };

    axios
      .post('https://app-grap.herokuapp.com/api/education', newRequest)
      .then((res) => {
        console.log(res);
        push('/NEEDS-assessment-survey');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ColorButton = styled(Button)(() => ({
    color: '#fff',
    width: 250,
    height: 55,
    fontWeight: 600,
    fontSize: '18px',
    textTransform: 'capitalize',
    backgroundColor: '#F58634',
    borderColor: '#F58634',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#81B214',
      borderColor: '#81B214',
    },
  }));

  return (
    <Box marginTop={{ xs: '22%', sm: '8%' }}>
      <Box marginBottom={4}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
          }}
          color="#F58634"
        >
          Education
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Email Address
            </Typography>
            <TextField
              label="Email Address"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Phone Number
            </Typography>
            <TextField
              required
              label="Phone Number"
              variant="outlined"
              name={'phone'}
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Gender
            </Typography>
            <FormControl required fullWidth>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                labelId="gender"
                id="gender"
                label="Gender"
                name={'gender'}
                fullWidth
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
              >
                <MenuItem value={'male'}>Male</MenuItem>
                <MenuItem value={'female'}>Female</MenuItem>
                <MenuItem value={'Prefer not to say'}>
                  Prefer not to say
                </MenuItem>
                <MenuItem value={'other'}>Others</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Age Range
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="age">Age Range</InputLabel>
              <Select
                labelId="age"
                id="age"
                label="Age Range"
                required
                name={'age'}
                fullWidth
                value={formik.values.age}
                onChange={formik.handleChange}
                error={formik.touched.age && Boolean(formik.errors.age)}
                helperText={formik.touched.age && formik.errors.age}
              >
                <MenuItem value={'18-24'}>18-24</MenuItem>
                <MenuItem value={'25-31'}>25-31</MenuItem>
                <MenuItem value={'32-39'}>32-39</MenuItem>
                <MenuItem value={'40-45'}>40-45</MenuItem>
                <MenuItem value={'46-51'}>46-51</MenuItem>
                <MenuItem value={'52 and above'}>52 and above</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Education Qualification
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="education">Education Qualification</InputLabel>
              <Select
                labelId="education"
                id="education"
                label="Education Qualification"
                required
                name={'education'}
                fullWidth
                value={formik.values.education}
                onChange={formik.handleChange}
                error={
                  formik.touched.education && Boolean(formik.errors.education)
                }
                helperText={formik.touched.education && formik.errors.education}
              >
                <MenuItem value={'SSCE'}>SSCE</MenuItem>
                <MenuItem value={'ND'}>ND</MenuItem>
                <MenuItem value={'NCE'}>NCE</MenuItem>
                <MenuItem value={'HND'}>HND</MenuItem>
                <MenuItem value={'BSc'}>BSc</MenuItem>
                <MenuItem value={'MSc'}>MSc</MenuItem>
                <MenuItem value={'PhD'}>PhD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Occupation
            </Typography>
            <TextField
              label="Your Occupation"
              required
              variant="outlined"
              name={'occupation'}
              fullWidth
              value={formik.values.occupation}
              onChange={formik.handleChange}
              error={
                formik.touched.occupation && Boolean(formik.errors.occupation)
              }
              helperText={formik.touched.occupation && formik.errors.occupation}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Any Disability?
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="disability">Any Disability? </InputLabel>
              <Select
                labelId="disability"
                id="disability"
                label="Any Disability?"
                required
                name={'disability'}
                fullWidth
                value={formik.values.disability}
                onChange={formik.handleChange}
                error={
                  formik.touched.disability && Boolean(formik.errors.disability)
                }
                helperText={
                  formik.touched.disability && formik.errors.disability
                }
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h6'}
              fontWeight={700}
              sx={{ marginBottom: 1 }}
            >
              Current State of Residence
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              State
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="state">State </InputLabel>
              <Select
                defaultValue={'Abuja (FCT)'}
                labelId="state"
                id="state"
                required
                label="State"
                name={'state'}
                fullWidth
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              >
                <MenuItem value="Abuja (FCT)">Abuja (FCT)</MenuItem>
                <MenuItem value="Abia State">Abia State</MenuItem>
                <MenuItem value="Adamawa State">Adamawa State</MenuItem>
                <MenuItem value="Akwa Ibom State">Akwa Ibom State</MenuItem>
                <MenuItem value="Anambra State">Anambra State</MenuItem>
                <MenuItem value="Bauchi State">Bauchi State</MenuItem>
                <MenuItem value="Bayelsa State">Bayelsa State</MenuItem>
                <MenuItem value="Benue State">Benue State</MenuItem>
                <MenuItem value="Borno State">Borno State</MenuItem>
                <MenuItem value="Cross River State">Cross River State</MenuItem>
                <MenuItem value="Delta State">Delta State</MenuItem>
                <MenuItem value="Ebonyi State">Ebonyi State</MenuItem>
                <MenuItem value="Edo State">Edo State</MenuItem>
                <MenuItem value="Ekiti State">Ekiti State</MenuItem>
                <MenuItem value="Enugu State">Enugu State</MenuItem>
                <MenuItem value="Gombe State">Gombe State</MenuItem>
                <MenuItem value="Imo State">Imo State</MenuItem>
                <MenuItem value="Jigawa State">Jigawa State</MenuItem>
                <MenuItem value="Kaduna State">Kaduna State</MenuItem>
                <MenuItem value="Kano State">Kano State</MenuItem>
                <MenuItem value="Katsina State">Katsina State</MenuItem>
                <MenuItem value="Kebbi State">Kebbi State</MenuItem>
                <MenuItem value="Kogi State">Kogi State</MenuItem>
                <MenuItem value="Kwara State">Kwara State</MenuItem>
                <MenuItem value="Lagos State">Lagos State</MenuItem>
                <MenuItem value="Nassarawa State">Nassarawa State</MenuItem>
                <MenuItem value="Niger State">Niger State</MenuItem>
                <MenuItem value="Ogun State">Ogun State</MenuItem>
                <MenuItem value="Ondo State">Ondo State</MenuItem>
                <MenuItem value="Osun State">Osun State</MenuItem>
                <MenuItem value="Oyo State">Oyo State</MenuItem>
                <MenuItem value="Plateau State">Plateau State</MenuItem>
                <MenuItem value="Rivers State">Rivers State</MenuItem>
                <MenuItem value="Sokoto State">Sokoto State</MenuItem>
                <MenuItem value="Taraba State">Taraba State</MenuItem>
                <MenuItem value="Yobe State">Yobe State</MenuItem>
                <MenuItem value="Zamfara State">Zamfara State</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              City/Town
            </Typography>
            <TextField
              label="City/Town"
              variant="outlined"
              required
              name={'city'}
              fullWidth
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Local Government Area
            </Typography>
            <Autocomplete
              fullWidth
              name={'lga'}
              onChange={(event, newValue) => {
                formik.setFieldValue('lga', newValue);
              }}
              id="lga"
              value={formik.values.lga}
              options={localGovt}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={'lga'}
                  required
                  label="Local Government Area"
                  error={formik.touched.lga && Boolean(formik.errors.lga)}
                  helperText={formik.touched.lga && formik.errors.lga}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Estimated Population
            </Typography>
            <TextField
              label="Estimated Population"
              variant="outlined"
              required
              name={'population'}
              fullWidth
              value={formik.values.population}
              onChange={formik.handleChange}
              error={
                formik.touched.population && Boolean(formik.errors.population)
              }
              helperText={formik.touched.population && formik.errors.population}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h6'}
              fontWeight={700}
              sx={{ marginBottom: 1 }}
            >
              Original State of Origin
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              State
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="state2">State </InputLabel>
              <Select
                labelId="state2"
                id="state2"
                defaultValue={'Abuja (FCT)'}
                label="State"
                required
                name={'state2'}
                fullWidth
                value={formik.values.state2}
                onChange={formik.handleChange}
                error={formik.touched.state2 && Boolean(formik.errors.state2)}
                helperText={formik.touched.state2 && formik.errors.state2}
              >
                <MenuItem value="Abuja (FCT)">Abuja (FCT)</MenuItem>
                <MenuItem value="Abia State">Abia State</MenuItem>
                <MenuItem value="Adamawa State">Adamawa State</MenuItem>
                <MenuItem value="Akwa Ibom State">Akwa Ibom State</MenuItem>
                <MenuItem value="Anambra State">Anambra State</MenuItem>
                <MenuItem value="Bauchi State">Bauchi State</MenuItem>
                <MenuItem value="Bayelsa State">Bayelsa State</MenuItem>
                <MenuItem value="Benue State">Benue State</MenuItem>
                <MenuItem value="Borno State">Borno State</MenuItem>
                <MenuItem value="Cross River State">Cross River State</MenuItem>
                <MenuItem value="Delta State">Delta State</MenuItem>
                <MenuItem value="Ebonyi State">Ebonyi State</MenuItem>
                <MenuItem value="Edo State">Edo State</MenuItem>
                <MenuItem value="Ekiti State">Ekiti State</MenuItem>
                <MenuItem value="Enugu State">Enugu State</MenuItem>
                <MenuItem value="Gombe State">Gombe State</MenuItem>
                <MenuItem value="Imo State">Imo State</MenuItem>
                <MenuItem value="Jigawa State">Jigawa State</MenuItem>
                <MenuItem value="Kaduna State">Kaduna State</MenuItem>
                <MenuItem value="Kano State">Kano State</MenuItem>
                <MenuItem value="Katsina State">Katsina State</MenuItem>
                <MenuItem value="Kebbi State">Kebbi State</MenuItem>
                <MenuItem value="Kogi State">Kogi State</MenuItem>
                <MenuItem value="Kwara State">Kwara State</MenuItem>
                <MenuItem value="Lagos State">Lagos State</MenuItem>
                <MenuItem value="Nassarawa State">Nassarawa State</MenuItem>
                <MenuItem value="Niger State">Niger State</MenuItem>
                <MenuItem value="Ogun State">Ogun State</MenuItem>
                <MenuItem value="Ondo State">Ondo State</MenuItem>
                <MenuItem value="Osun State">Osun State</MenuItem>
                <MenuItem value="Oyo State">Oyo State</MenuItem>
                <MenuItem value="Plateau State">Plateau State</MenuItem>
                <MenuItem value="Rivers State">Rivers State</MenuItem>
                <MenuItem value="Sokoto State">Sokoto State</MenuItem>
                <MenuItem value="Taraba State">Taraba State</MenuItem>
                <MenuItem value="Yobe State">Yobe State</MenuItem>
                <MenuItem value="Zamfara State">Zamfara State</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              City/Town
            </Typography>
            <TextField
              label="City/Town"
              required
              variant="outlined"
              name={'city2'}
              fullWidth
              value={formik.values.city2}
              onChange={formik.handleChange}
              error={formik.touched.city2 && Boolean(formik.errors.city2)}
              helperText={formik.touched.city2 && formik.errors.city2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Local Government Area
            </Typography>
            <Autocomplete
              fullWidth
              name={'lga2'}
              onChange={(event, newValue) => {
                formik.setFieldValue('lga2', newValue);
              }}
              id="lga2"
              value={formik.values.lga2}
              options={localGovt}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={'lga2'}
                  required
                  label="Local Government Area"
                  error={formik.touched.lga2 && Boolean(formik.errors.lga2)}
                  helperText={formik.touched.lga2 && formik.errors.lga2}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Estimated Population
            </Typography>
            <TextField
              label="Estimated Population"
              required
              variant="outlined"
              name={'population2'}
              fullWidth
              value={formik.values.population2}
              onChange={formik.handleChange}
              error={
                formik.touched.population2 && Boolean(formik.errors.population2)
              }
              helperText={
                formik.touched.population2 && formik.errors.population2
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'h6'} fontWeight={400}>
              Please, select the options that is/are affecting basic education
              in your community.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h6'}
              fontWeight={700}
              sx={{ marginBottom: 1 }}
            >
              Fact finding on problems
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Is there any government primary school located in your community?
              How many?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu1"
                id="edu1"
                required
                name={'edu1'}
                fullWidth
                value={formik.values.edu1}
                onChange={formik.handleChange}
                error={formik.touched.edu1 && Boolean(formik.errors.edu1)}
                helperText={formik.touched.edu1 && formik.errors.edu1}
              >
                <MenuItem value={'1-2'}>1-2</MenuItem>
                <MenuItem value={'3-5'}>3-5</MenuItem>
                <MenuItem value={'6-10'}>6-10</MenuItem>
                <MenuItem value={'11 & above'}>11 & above</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              What is/are the statuses of the primary schools?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu2"
                id="edu2"
                name={'edu2'}
                required
                fullWidth
                value={formik.values.edu2}
                onChange={formik.handleChange}
                error={formik.touched.edu2 && Boolean(formik.errors.edu2)}
                helperText={formik.touched.edu2 && formik.errors.edu2}
              >
                <MenuItem
                  value={'Functional—key infrastructures & good teachers'}
                >
                  Functional—key infrastructures & good teachers
                </MenuItem>
                <MenuItem
                  value={'Functional—key infrastructures with few teachers'}
                >
                  Functional—key infrastructures with few teachers
                </MenuItem>
                <MenuItem value={'Bad infrastructures without teachers'}>
                  Bad infrastructures without teachers
                </MenuItem>
                <MenuItem value={'Insecurity of teachers & pupils'}>
                  Insecurity of teachers & pupils
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Are there abandoned government projects in the school? How many?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu3"
                id="edu3"
                name={'edu3'}
                required
                fullWidth
                value={formik.values.edu3}
                onChange={formik.handleChange}
                error={formik.touched.edu3 && Boolean(formik.errors.edu3)}
                helperText={formik.touched.edu3 && formik.errors.edu3}
              >
                <MenuItem value={'1-2'}>1-2</MenuItem>
                <MenuItem value={'3-5'}>3-5</MenuItem>
                <MenuItem value={'6-10'}>6-10</MenuItem>
                <MenuItem value={'11 & above'}>11 & above</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              What is the average population of the school(s)?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu4"
                id="edu4"
                name={'edu4'}
                required
                fullWidth
                value={formik.values.edu4}
                onChange={formik.handleChange}
                error={formik.touched.edu4 && Boolean(formik.errors.edu4)}
                helperText={formik.touched.edu4 && formik.errors.edu4}
              >
                <MenuItem value={'1-200'}>1-200</MenuItem>
                <MenuItem value={'201-500'}>201-500</MenuItem>
                <MenuItem value={'501-1000'}>501-1000</MenuItem>
                <MenuItem value={'1001-2000'}>1001-2000</MenuItem>
                <MenuItem value={'2001 & above'}>2001 & above</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Is there a computer lab in the school(s) for computer elementary?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu5"
                id="edu5"
                name={'edu5'}
                required
                fullWidth
                value={formik.values.edu5}
                onChange={formik.handleChange}
                error={formik.touched.edu5 && Boolean(formik.errors.edu5)}
                helperText={formik.touched.edu5 && formik.errors.edu5}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'No teachers to teach digital education'}>
                  No teachers to teach digital education
                </MenuItem>
                <MenuItem value={'Computers are old—not working'}>
                  Computers are old—not working
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Are there any government secondary schools located in your
              community? How many?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu6"
                id="edu6"
                required
                name={'edu6'}
                fullWidth
                value={formik.values.edu6}
                onChange={formik.handleChange}
                error={formik.touched.edu6 && Boolean(formik.errors.edu6)}
                helperText={formik.touched.edu6 && formik.errors.edu6}
              >
                <MenuItem value={'1-2'}>1-2</MenuItem>
                <MenuItem value={'3-5'}>3-5</MenuItem>
                <MenuItem value={'6-10'}>6-10</MenuItem>
                <MenuItem value={'11 & above'}>11 & above</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              What is/are the statuses of the secondary schools?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu7"
                id="edu7"
                name={'edu7'}
                required
                fullWidth
                value={formik.values.edu7}
                onChange={formik.handleChange}
                error={formik.touched.edu7 && Boolean(formik.errors.edu7)}
                helperText={formik.touched.edu7 && formik.errors.edu7}
              >
                <MenuItem
                  value={'Functional—key infrastructures & good teachers'}
                >
                  Functional—key infrastructures & good teachers
                </MenuItem>
                <MenuItem
                  value={'Functional—key infrastructures with few teachers'}
                >
                  Functional—key infrastructures with few teachers
                </MenuItem>
                <MenuItem value={'Bad infrastructures without teachers'}>
                  Bad infrastructures without teachers
                </MenuItem>
                <MenuItem value={'Insecurity of teachers & students'}>
                  Insecurity of teachers & students
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Are there abandoned government projects in the school? How many?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu8"
                required
                id="edu8"
                name={'edu8'}
                fullWidth
                value={formik.values.edu8}
                onChange={formik.handleChange}
                error={formik.touched.edu8 && Boolean(formik.errors.edu8)}
                helperText={formik.touched.edu8 && formik.errors.edu8}
              >
                <MenuItem value={'1-2'}>1-2</MenuItem>
                <MenuItem value={'3-5'}>3-5</MenuItem>
                <MenuItem value={'6-10'}>6-10</MenuItem>
                <MenuItem value={'11 & above'}>11 & above</MenuItem>
                <MenuItem value={'None'}>None</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              What is the average population of the school(s)?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu9"
                id="edu9"
                required
                name={'edu9'}
                fullWidth
                value={formik.values.edu9}
                onChange={formik.handleChange}
                error={formik.touched.edu9 && Boolean(formik.errors.edu9)}
                helperText={formik.touched.edu9 && formik.errors.edu9}
              >
                <MenuItem value={'1-200'}>1-200</MenuItem>
                <MenuItem value={'201-500'}>201-500</MenuItem>
                <MenuItem value={'501-1000'}>501-1000</MenuItem>
                <MenuItem value={'1001-2000'}>1001-2000</MenuItem>
                <MenuItem value={'2001 & above'}>2001 & above</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Is there a computer lab in the school(s)?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="edu10"
                required
                id="edu10"
                name={'edu10'}
                fullWidth
                value={formik.values.edu10}
                onChange={formik.handleChange}
                error={formik.touched.edu10 && Boolean(formik.errors.edu10)}
                helperText={formik.touched.edu10 && formik.errors.edu10}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'No teachers to teach digital education'}>
                  No teachers to teach digital education
                </MenuItem>
                <MenuItem value={'Computers are old—not working'}>
                  Computers are old—not working
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item container xs={12}>
            <Box width={'100%'}>
              <ColorButton size={'large'} variant={'contained'} type={'submit'}>
                Submit
              </ColorButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
