import React from 'react';
import Box from '@mui/material/Box';
import { Card, Typography } from '@mui/material';
import { axiosWithAuth } from 'utils/axiosWithAuth';

const Dashboard = () => {

  const [postList, setPostList] = React.useState([]);
  const [agricList, setAgricList] = React.useState([]);
  const [educationList, setEducationList] = React.useState([]);
  const [youthList, setYouthList] = React.useState([]);

  React.useEffect(() => {
    axiosWithAuth()
      .get('/posts')
      .then((res) => {
        setPostList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  React.useEffect(() => {
    axiosWithAuth()
      .get('/agric')
      .then((res) => {
        setAgricList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  React.useEffect(() => {
    axiosWithAuth()
      .get('/education')
      .then((res) => {
        setEducationList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  React.useEffect(() => {
    axiosWithAuth()
      .get('/youth')
      .then((res) => {
        setYouthList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <Box margin={'12% 0%'} paddingLeft={{ xs: '0%', sm: '3%' }}>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          color="#81B214"
          sx={{
            fontWeight: 700,
            
          }}
        >
          Dashboard
        </Typography>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        width={'100%'}
      >
        <Box component={Card} width={'30%'} padding={'2%'}>
          <Typography
            variant={'subtitle2'}
            sx={{ marginBottom: 2 }}
            textAlign={'center'}
            color="#F58634"
          >
            No of Agric NEEDS Assessment
          </Typography>
          <Typography
            variant="h1"
            fontWeight={700}
            color={'#4f4f4f'}
            textAlign={'center'}
          >
            {agricList.length}
          </Typography>
        </Box>
        <Box component={Card} width={'30%'} padding={'2%'}>
          <Typography
            variant={'subtitle2'}
            sx={{ marginBottom: 2 }}
            textAlign={'center'}
            color="#F58634"
          >
            No of Education NEEDS Assessment
          </Typography>
          <Typography
            variant="h1"
            fontWeight={700}
            color={'#4f4f4f'}
            textAlign={'center'}
          >
            {educationList.length}
          </Typography>
        </Box>
        <Box component={Card} width={'30%'} padding={'2%'}>
          <Typography
            variant={'subtitle2'}
            sx={{ marginBottom: 2 }}
            textAlign={'center'}
            color="#F58634"
          >
            No of FCT Youth NEEDS Assessment
          </Typography>
          <Typography
            variant="h1"
            fontWeight={700}
            color={'#4f4f4f'}
            textAlign={'center'}
          >
            {youthList.length}
          </Typography>
        </Box>
        <Box component={Card} width={'30%'} padding={'2%'} marginTop={'2%'}>
          <Typography
            variant={'subtitle2'}
            sx={{ marginBottom: 2 }}
            textAlign={'center'}
            color="#F58634"
          >
            No of Blogs
          </Typography>
          <Typography
            variant="h1"
            fontWeight={700}
            color={'#4f4f4f'}
            textAlign={'center'}
          >
            {postList.length}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
