export const communities = [
  'Durumi Community',
  'Jabi Community',
  'Jayi Community',
  'Karimo Community',
  'Abawa Community',
  'Manko Community',
  'Nupe Community',
  'Old Market Community',
  'Senior Staff Quarters Community',
  'Byahzin Community',
  'Dawaki Community',
  'Dei-Dei Community',
  'Dutse community',
  'Kuchibuyi Community',
  'Sabon Gari Bwari Community',
  'Dukpa Community',
  'Paiko Kore Community',
  'Passo Community',
  'Sabon-Gari Community',
  'Bonugu Community',
  'Bako/Bobota Community',
  'Kwali Township',
  'Lambata Community',
  'Sheda Galadima Community',
  'Bamish Community',
  'Dafara Community',
  'Pasali Community',
  'Rije Community',
  'Sauka Community',
  'Others',
];
