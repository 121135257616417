import React from 'react';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import Autocomplete from '@mui/material/Autocomplete';
import {localGovt} from './data';

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .trim()
    .email('Please enter a valid email address'),
  phone: yup
    .string('Enter your phone number')
    .trim()
    .min(11, 'Please enter a valid phone number')
    .required('Please specify your phone number'),
  gender: yup
    .string('Select your gender ')
    .trim()
    .required('Please specify your gender'),
  age: yup
    .string('Select your age range')
    .trim()
    .required('Please specify your age range'),
  education: yup
    .string('Select your Education Qualification')
    .trim()
    .required('Please specify your Education Qualification'),
  occupation: yup
    .string('Enter your occupation')
    .trim()
    .required('Please specify your occupation'),
  disability: yup
    .string('Select your disability option')
    .trim()
    .required('Please specify your disability option'),
  state: yup
    .string('Select your state of residence')
    .trim()
    .required('Please specify your state of residence'),
  city: yup
    .string('Enter your city of residence')
    .trim()
    .required('Please specify your city of residence'),
  lga: yup
    .string('Select your local govt of residence')
    .trim()
    .required('Please specify your local govt of residence'),
  population: yup
    .string('Enter your population of residence')
    .trim()
    .required('Please specify the population of residence'),
  state2: yup
    .string('Select your state of origin')
    .trim()
    .required('Please specify your state of origin'),
  city2: yup
    .string('Enter your city of origin')
    .trim()
    .required('Please specify your city of origin'),
  lga2: yup
    .string('Select your local govt of origin')
    .trim()
    .required('Please specify your local govt of origin'),
  population2: yup
    .string('Enter your population of origin')
    .trim()
    .required('Please specify the population of origin'),
  agric1: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  agric2: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
  agric3: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  agric4: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
  agric5: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  agric6: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
  agric7: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  agric8: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
  agric9: yup
    .string('Select your option')
    .trim()
    .required('Please specify your option'),
  agric10: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
  agric11: yup
    .string('Enter your option')
    .trim()
    .required('Please specify your option'),
});

const Form = () => {

  const { push } = useHistory();
  const initialValues = {
    email: '',
    phone: '',
    gender: '',
    age: '',
    education: '',
    occupation: '',
    disability: '',
    city: '',
    lga: '',
    population: '',
    city2: '',
    lga2: '',
    population2: '',
    state: '',
    state2: '',
    agric1: '',
    agric2: '',
    agric3: '',
    agric4: '',
    agric5: '',
    agric6: '',
    agric7: '',
    agric8: '',
    agric9: '',
    agric10: '',
    agric11: '',
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    option5: false,
    option6: false,
    option7: false,
    option8: false,
    option9: false,
    option10: false,
    option11: false,
  };

  const onSubmit = (values) => {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const newRequest = {
      email: formik.values.email.trim(),
      phone: formik.values.phone.trim(),
      gender: formik.values.gender.trim(),
      age: formik.values.age.trim(),
      education: formik.values.education.trim(),
      occupation: formik.values.occupation.trim(),
      disability: formik.values.disability.trim(),
      state: formik.values.state,
      city: formik.values.city.trim(),
      lga: formik.values.lga,
      population: formik.values.population.trim(),
      state2: formik.values.state2,
      city2: formik.values.city2.trim(),
      lga2: formik.values.lga2,
      population2: formik.values.population2.trim(),
      agric1: formik.values.agric1.trim(),
      agric2: formik.values.agric2.trim(),
      agric3: formik.values.agric3.trim(),
      agric4: formik.values.agric4.trim(),
      agric5: formik.values.agric5.trim(),
      agric6: formik.values.agric6.trim(),
      agric7: formik.values.agric7.trim(),
      agric8: formik.values.agric8.trim(),
      agric9: formik.values.agric9.trim(),
      agric10: formik.values.agric10.trim(),
      agric11: formik.values.agric11.trim(),
      option1: formik.values.option1,
      option2: formik.values.option2,
      option3: formik.values.option3,
      option4: formik.values.option4,
      option5: formik.values.option5,
      option6: formik.values.option6,
      option7: formik.values.option7,
      option8: formik.values.option8,
      option9: formik.values.option9,
      option10: formik.values.option10,
      option11: formik.values.option11,
    };

    axios
      .post('https://app-grap.herokuapp.com/api/agric', newRequest)
      .then((res) => {
        console.log(res);
        push('/NEEDS-assessment-survey');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const ColorButton = styled(Button)(() => ({
    color: '#fff',
    width: 250,
    height: 55,
    fontWeight: 600,
    fontSize: '18px',
    textTransform: 'capitalize',
    backgroundColor: '#F58634',
    borderColor: '#F58634',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#81B214',
      borderColor: '#81B214',
    },
  }));

  return (
    <Box marginTop={{ xs: '22%', sm: '8%' }}>
      <Box marginBottom={4}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 700,
          }}
          color="#F58634"
        >
          Agric & Rural Development
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Email Address
            </Typography>
            <TextField
              label="Email Address"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Phone Number
            </Typography>
            <TextField
              required
              label="Phone Number"
              variant="outlined"
              name={'phone'}
              fullWidth
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Gender
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                required
                labelId="gender"
                id="gender"
                label="Gender"
                name={'gender'}
                fullWidth
                value={formik.values.gender}
                onChange={formik.handleChange}
                error={formik.touched.gender && Boolean(formik.errors.gender)}
                helperText={formik.touched.gender && formik.errors.gender}
              >
                <MenuItem value={'male'}>Male</MenuItem>
                <MenuItem value={'female'}>Female</MenuItem>
                <MenuItem value={'Prefer not to say'}>
                  Prefer not to say
                </MenuItem>
                <MenuItem value={'other'}>Others</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Age Range
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="age">Age Range</InputLabel>
              <Select
                required
                labelId="age"
                id="age"
                label="Age Range"
                name={'age'}
                fullWidth
                value={formik.values.age}
                onChange={formik.handleChange}
                error={formik.touched.age && Boolean(formik.errors.age)}
                helperText={formik.touched.age && formik.errors.age}
              >
                <MenuItem value={'18-24'}>18-24</MenuItem>
                <MenuItem value={'25-31'}>25-31</MenuItem>
                <MenuItem value={'32-39'}>32-39</MenuItem>
                <MenuItem value={'40-45'}>40-45</MenuItem>
                <MenuItem value={'46-51'}>46-51</MenuItem>
                <MenuItem value={'52 and above'}>52 and above</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Education Qualification
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="education">Education Qualification</InputLabel>
              <Select
                required
                labelId="education"
                id="education"
                label="Education Qualification"
                name={'education'}
                fullWidth
                value={formik.values.education}
                onChange={formik.handleChange}
                error={
                  formik.touched.education && Boolean(formik.errors.education)
                }
                helperText={formik.touched.education && formik.errors.education}
              >
                <MenuItem value={'SSCE'}>SSCE</MenuItem>
                <MenuItem value={'ND'}>ND</MenuItem>
                <MenuItem value={'NCE'}>NCE</MenuItem>
                <MenuItem value={'HND'}>HND</MenuItem>
                <MenuItem value={'BSc'}>BSc</MenuItem>
                <MenuItem value={'MSc'}>MSc</MenuItem>
                <MenuItem value={'PhD'}>PhD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Occupation
            </Typography>
            <TextField
              required
              label="Your Occupation"
              variant="outlined"
              name={'occupation'}
              fullWidth
              value={formik.values.occupation}
              onChange={formik.handleChange}
              error={
                formik.touched.occupation && Boolean(formik.errors.occupation)
              }
              helperText={formik.touched.occupation && formik.errors.occupation}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Any Disability?
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="disability">Any Disability? </InputLabel>
              <Select
                required
                labelId="disability"
                id="disability"
                label="Any Disability?"
                name={'disability'}
                fullWidth
                value={formik.values.disability}
                onChange={formik.handleChange}
                error={
                  formik.touched.disability && Boolean(formik.errors.disability)
                }
                helperText={
                  formik.touched.disability && formik.errors.disability
                }
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h6'}
              fontWeight={700}
              sx={{ marginBottom: 1 }}
            >
              Current State of Residence
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              State
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="state">State </InputLabel>
              <Select
                required
                labelId="state"
                id="state"
                label="State"
                name={'state'}
                fullWidth
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              >
                <MenuItem value="Abuja (FCT)">Abuja (FCT)</MenuItem>
                <MenuItem value="Abia State">Abia State</MenuItem>
                <MenuItem value="Adamawa State">Adamawa State</MenuItem>
                <MenuItem value="Akwa Ibom State">Akwa Ibom State</MenuItem>
                <MenuItem value="Anambra State">Anambra State</MenuItem>
                <MenuItem value="Bauchi State">Bauchi State</MenuItem>
                <MenuItem value="Bayelsa State">Bayelsa State</MenuItem>
                <MenuItem value="Benue State">Benue State</MenuItem>
                <MenuItem value="Borno State">Borno State</MenuItem>
                <MenuItem value="Cross River State">Cross River State</MenuItem>
                <MenuItem value="Delta State">Delta State</MenuItem>
                <MenuItem value="Ebonyi State">Ebonyi State</MenuItem>
                <MenuItem value="Edo State">Edo State</MenuItem>
                <MenuItem value="Ekiti State">Ekiti State</MenuItem>
                <MenuItem value="Enugu State">Enugu State</MenuItem>
                <MenuItem value="Gombe State">Gombe State</MenuItem>
                <MenuItem value="Imo State">Imo State</MenuItem>
                <MenuItem value="Jigawa State">Jigawa State</MenuItem>
                <MenuItem value="Kaduna State">Kaduna State</MenuItem>
                <MenuItem value="Kano State">Kano State</MenuItem>
                <MenuItem value="Katsina State">Katsina State</MenuItem>
                <MenuItem value="Kebbi State">Kebbi State</MenuItem>
                <MenuItem value="Kogi State">Kogi State</MenuItem>
                <MenuItem value="Kwara State">Kwara State</MenuItem>
                <MenuItem value="Lagos State">Lagos State</MenuItem>
                <MenuItem value="Nassarawa State">Nassarawa State</MenuItem>
                <MenuItem value="Niger State">Niger State</MenuItem>
                <MenuItem value="Ogun State">Ogun State</MenuItem>
                <MenuItem value="Ondo State">Ondo State</MenuItem>
                <MenuItem value="Osun State">Osun State</MenuItem>
                <MenuItem value="Oyo State">Oyo State</MenuItem>
                <MenuItem value="Plateau State">Plateau State</MenuItem>
                <MenuItem value="Rivers State">Rivers State</MenuItem>
                <MenuItem value="Sokoto State">Sokoto State</MenuItem>
                <MenuItem value="Taraba State">Taraba State</MenuItem>
                <MenuItem value="Yobe State">Yobe State</MenuItem>
                <MenuItem value="Zamfara State">Zamfara State</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              City/Town
            </Typography>
            <TextField
              required
              label="City/Town"
              variant="outlined"
              name={'city'}
              fullWidth
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Local Government Area
            </Typography>
            <Autocomplete
              fullWidth
              name={'lga'}
              onChange={(event, newValue) => {
                formik.setFieldValue('lga', newValue);
              }}
              id="lga"
              value={formik.values.lga}
              options={localGovt}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={'lga'}
                  required
                  label="Local Government Area"
                  error={formik.touched.lga && Boolean(formik.errors.lga)}
                  helperText={formik.touched.lga && formik.errors.lga}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Estimated Population
            </Typography>
            <TextField
              required
              label="Estimated Population"
              variant="outlined"
              name={'population'}
              fullWidth
              value={formik.values.population}
              onChange={formik.handleChange}
              error={
                formik.touched.population && Boolean(formik.errors.population)
              }
              helperText={formik.touched.population && formik.errors.population}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h6'}
              fontWeight={700}
              sx={{ marginBottom: 1 }}
            >
              Original State of Origin
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              State
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="state2">State </InputLabel>
              <Select
                required
                labelId="state2"
                id="state2"
                label="State"
                name={'state2'}
                fullWidth
                value={formik.values.state2}
                onChange={formik.handleChange}
                error={formik.touched.state2 && Boolean(formik.errors.state2)}
                helperText={formik.touched.state2 && formik.errors.state2}
              >
                <MenuItem value="Abuja (FCT)">Abuja (FCT)</MenuItem>
                <MenuItem value="Abia State">Abia State</MenuItem>
                <MenuItem value="Adamawa State">Adamawa State</MenuItem>
                <MenuItem value="Akwa Ibom State">Akwa Ibom State</MenuItem>
                <MenuItem value="Anambra State">Anambra State</MenuItem>
                <MenuItem value="Bauchi State">Bauchi State</MenuItem>
                <MenuItem value="Bayelsa State">Bayelsa State</MenuItem>
                <MenuItem value="Benue State">Benue State</MenuItem>
                <MenuItem value="Borno State">Borno State</MenuItem>
                <MenuItem value="Cross River State">Cross River State</MenuItem>
                <MenuItem value="Delta State">Delta State</MenuItem>
                <MenuItem value="Ebonyi State">Ebonyi State</MenuItem>
                <MenuItem value="Edo State">Edo State</MenuItem>
                <MenuItem value="Ekiti State">Ekiti State</MenuItem>
                <MenuItem value="Enugu State">Enugu State</MenuItem>
                <MenuItem value="Gombe State">Gombe State</MenuItem>
                <MenuItem value="Imo State">Imo State</MenuItem>
                <MenuItem value="Jigawa State">Jigawa State</MenuItem>
                <MenuItem value="Kaduna State">Kaduna State</MenuItem>
                <MenuItem value="Kano State">Kano State</MenuItem>
                <MenuItem value="Katsina State">Katsina State</MenuItem>
                <MenuItem value="Kebbi State">Kebbi State</MenuItem>
                <MenuItem value="Kogi State">Kogi State</MenuItem>
                <MenuItem value="Kwara State">Kwara State</MenuItem>
                <MenuItem value="Lagos State">Lagos State</MenuItem>
                <MenuItem value="Nassarawa State">Nassarawa State</MenuItem>
                <MenuItem value="Niger State">Niger State</MenuItem>
                <MenuItem value="Ogun State">Ogun State</MenuItem>
                <MenuItem value="Ondo State">Ondo State</MenuItem>
                <MenuItem value="Osun State">Osun State</MenuItem>
                <MenuItem value="Oyo State">Oyo State</MenuItem>
                <MenuItem value="Plateau State">Plateau State</MenuItem>
                <MenuItem value="Rivers State">Rivers State</MenuItem>
                <MenuItem value="Sokoto State">Sokoto State</MenuItem>
                <MenuItem value="Taraba State">Taraba State</MenuItem>
                <MenuItem value="Yobe State">Yobe State</MenuItem>
                <MenuItem value="Zamfara State">Zamfara State</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              City/Town
            </Typography>
            <TextField
              required
              label="City/Town"
              variant="outlined"
              name={'city2'}
              fullWidth
              value={formik.values.city2}
              onChange={formik.handleChange}
              error={formik.touched.city2 && Boolean(formik.errors.city2)}
              helperText={formik.touched.city2 && formik.errors.city2}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Local Government Area
            </Typography>
            <Autocomplete
              fullWidth
              name={'lga2'}
              onChange={(event, newValue) => {
                formik.setFieldValue('lga2', newValue);
              }}
              id="lga2"
              value={formik.values.lga2}
              options={localGovt}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name={'lga2'}
                  required
                  label="Local Government Area"
                  error={formik.touched.lga2 && Boolean(formik.errors.lga2)}
                  helperText={formik.touched.lga2 && formik.errors.lga2}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Estimated Population
            </Typography>
            <TextField
              required
              label="Estimated Population"
              variant="outlined"
              name={'population2'}
              fullWidth
              value={formik.values.population2}
              onChange={formik.handleChange}
              error={
                formik.touched.population2 && Boolean(formik.errors.population2)
              }
              helperText={
                formik.touched.population2 && formik.errors.population2
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'h6'} fontWeight={400}>
              Please, select the options that is/are affecting your community.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant={'h6'}
              fontWeight={700}
              sx={{ marginBottom: 1 }}
            >
              Fact finding on problems
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              When was the last time farm inputs from the government were
              distributed to farmers in your community?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric1"
                id="agric1"
                name={'agric1'}
                fullWidth
                value={formik.values.agric1}
                onChange={formik.handleChange}
                error={formik.touched.agric1 && Boolean(formik.errors.agric1)}
                helperText={formik.touched.agric1 && formik.errors.agric1}
              >
                <MenuItem value={'1-5 yrs'}>1-5 yrs</MenuItem>
                <MenuItem value={'6-10 yrs'}>6-10 yrs</MenuItem>
                <MenuItem value={'11-20 yrs'}>11-20 yrs</MenuItem>
                <MenuItem value={'21 yrs & above'}>21 yrs & above</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              When was the last time the rural access roads to farms and
              villages were constructed or graded?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric2"
                id="agric2"
                name={'agric2'}
                fullWidth
                value={formik.values.agric2}
                onChange={formik.handleChange}
                error={formik.touched.agric2 && Boolean(formik.errors.agric2)}
                helperText={formik.touched.agric2 && formik.errors.agric2}
              >
                <MenuItem value={'1-5 yrs'}>1-5 yrs</MenuItem>
                <MenuItem value={'6-10 yrs'}>6-10 yrs</MenuItem>
                <MenuItem value={'11-20 yrs'}>11-20 yrs</MenuItem>
                <MenuItem value={'21 yrs & above'}>21 yrs & above</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Are there any government farm settlements in your community and
              still operational and functional?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric3"
                id="agric3"
                name={'agric3'}
                fullWidth
                value={formik.values.agric3}
                onChange={formik.handleChange}
                error={formik.touched.agric3 && Boolean(formik.errors.agric3)}
                helperText={formik.touched.agric3 && formik.errors.agric3}
              >
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'Yes, operational'}>Yes, operational</MenuItem>
                <MenuItem value={'Yes, not operational'}>
                  Yes, not operationale
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              When was the last time government Agric Extension Workers (AGW)
              visited your community to educate farmers?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric4"
                id="agric4"
                name={'agric4'}
                fullWidth
                value={formik.values.agric4}
                onChange={formik.handleChange}
                error={formik.touched.agric4 && Boolean(formik.errors.agric4)}
                helperText={formik.touched.agric4 && formik.errors.agric4}
              >
                <MenuItem value={'1-5 yrs'}>1-5 yrs</MenuItem>
                <MenuItem value={'6-10 yrs'}>6-10 yrs</MenuItem>
                <MenuItem value={'11-20 yrs'}>11-20 yrs</MenuItem>
                <MenuItem value={'21 yrs & above'}>21 yrs & above</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Is there a farm produce commodity market in your local government?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric5"
                id="agric5"
                name={'agric5'}
                fullWidth
                value={formik.values.agric5}
                onChange={formik.handleChange}
                error={formik.touched.agric5 && Boolean(formik.errors.agric5)}
                helperText={formik.touched.agric5 && formik.errors.agric5}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
                <MenuItem value={'Yes, no longer open'}>
                  Yes, no longer open
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Are you aware of the negative effects of climate change on
              agriculture and farming?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric6"
                id="agric6"
                name={'agric6'}
                fullWidth
                value={formik.values.agric6}
                onChange={formik.handleChange}
                error={formik.touched.agric6 && Boolean(formik.errors.agric6)}
                helperText={formik.touched.agric6 && formik.errors.agric6}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Has your community been involved in the farmer/herder crisis?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric7"
                id="agric7"
                name={'agric7'}
                fullWidth
                value={formik.values.agric7}
                onChange={formik.handleChange}
                error={formik.touched.agric7 && Boolean(formik.errors.agric7)}
                helperText={formik.touched.agric7 && formik.errors.agric7}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Has oil exploration affected farming, animal rearing or fishing in
              your community?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric8"
                id="agric8"
                name={'agric8'}
                fullWidth
                value={formik.values.agric8}
                onChange={formik.handleChange}
                error={formik.touched.agric8 && Boolean(formik.errors.agric8)}
                helperText={formik.touched.agric8 && formik.errors.agric8}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Has gas flaring and environmental pollution affected farming,
              fishing and animal rearing in your community?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric9"
                id="agric9"
                name={'agric9'}
                fullWidth
                value={formik.values.agric9}
                onChange={formik.handleChange}
                error={formik.touched.agric9 && Boolean(formik.errors.agric9)}
                helperText={formik.touched.agric9 && formik.errors.agric9}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Is there any agricultural produce storage facility (silos) in your
              community/local government?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric10"
                id="agric10"
                name={'agric10'}
                fullWidth
                value={formik.values.agric10}
                onChange={formik.handleChange}
                error={formik.touched.agric10 && Boolean(formik.errors.agric10)}
                helperText={formik.touched.agric10 && formik.errors.agric10}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Do farmers and agribusiness men and women in your community have
              cooperative associations?
            </Typography>
            <FormControl fullWidth>
              <Select
                required
                labelId="agric11"
                id="agric11"
                name={'agric11'}
                fullWidth
                value={formik.values.agric11}
                onChange={formik.handleChange}
                error={formik.touched.agric11 && Boolean(formik.errors.agric11)}
                helperText={formik.touched.agric11 && formik.errors.agric11}
              >
                <MenuItem value={'Yes'}>Yes</MenuItem>
                <MenuItem value={'No'}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'h6'} fontWeight={700}>
              Proposed solutions for budget consideration
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={'h6'} fontWeight={400}>
              Please tick the options that is/are needed for your community to
              improve agriculture.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={formik.values.option1}  name="option1"/> }
                onChange={formik.handleChange}
                value= {formik.values.option1}
                label="Provision of farm inputs such as seedlings, farm tools, pump sprayers, fertilizers and chemicals."
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option2} /> }
                name="option2"
                value= {formik.values.option2}
                onChange={formik.handleChange}
                label="Opening up of rural access roads to villages and farm settlements."
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option3} /> }
                name="option3"
                value= {formik.values.option3}
                onChange={formik.handleChange}
                label="Establishment of new farm settlements"
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option4} /> }
                name="option4"
                value= {formik.values.option4}
                onChange={formik.handleChange}
                label="Re-introduction of agric extension workers for grassroots farmers."
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option5} /> }
                name="option5"
                value= {formik.values.option5}
                onChange={formik.handleChange}
                label="Design and implementation of agribusiness training for youth and women."
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option6} /> }
                name="option6"
                value= {formik.values.option6}
                onChange={formik.handleChange}
                label="Provision of training and sensitization on climate-smart agricultural practices."
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option7} /> }
                name="option7"
                value= {formik.values.option7}
                onChange={formik.handleChange}
                label="Establishment of Agricultural produce commodity market."
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option8} /> }
                name="option8"
                value= {formik.values.option8}
                onChange={formik.handleChange}
                label="Provision of basic tech training for agribusiness owners to improve communication and to access competitive market information."
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option9} /> }
                name="option9"
                value= {formik.values.option9}
                onChange={formik.handleChange}
                label="Reactivation of agricultural produce storage facilities (silos) for farmers"
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option10} /> }
                name="option10"
                value= {formik.values.option10}
                onChange={formik.handleChange}
                label="Policy development to encourage private-sector led investments in agricultural produce storage facilities in local communities."
              />
              <FormControlLabel
                control={<Checkbox checked={formik.values.option11} /> }
                name="option11"
                value= {formik.values.option11}
                onChange={formik.handleChange}
                label="Design and implementation of grant/loan facilities for youth and women in agribusiness in local communities. "
              />
            </FormGroup>
          </Grid>
          <Grid item container xs={12}>
            <Box width={'100%'}>
              <ColorButton size={'large'} variant={'contained'} type={'submit'}>
                Submit
              </ColorButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
