import React, { useState } from 'react';
import Box from '@mui/material/Box';
import DetailAgric from './DetailAgric';
import TheAgric from './TheAgric';
import { axiosWithAuth } from 'utils/axiosWithAuth';

const initialAgric = {
  email: '',
  phone: '',
  gender: '',
  age: '',
  education: '',
  occupation: '',
  disability: '',
  city: '',
  lga: '',
  population: '',
  city2: '',
  lga2: '',
  population2: '',
  state: '',
  state2: '',
  agric1: '',
  agric2: '',
  agric3: '',
  agric4: '',
  agric5: '',
  agric6: '',
  agric7: '',
  agric8: '',
  agric9: '',
  agric10: '',
  agric11: '',
  option1: false,
  option2: false,
  option3: false,
  option4: false,
  option5: false,
  option6: false,
  option7: false,
  option8: false,
  option9: false,
  option10: false,
  option11: false,
};

const AgricList = ({ agriculture }) => {
  const [detail, setDetail] = useState(false);
  const [agricDetail, setAgricDetail] = useState(initialAgric);
  const [delAgric, setDelAgric] = useState(false);

  const detailAgric = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .get(`/agric/${agriculture.agric_id}`)
      .then((res) => {
        setAgricDetail(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewAgric = (agric) => {
    setDetail(true);
    setAgricDetail(agric);
  };

  const handleClose = () => setDetail(false);

  const deleteAgric = (agric) => {
    axiosWithAuth()
      .delete(`/agric/${agric.agric_id}`)
      .then((res) => {
        setDelAgric(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <Box>
        {agriculture.map((agric) => (
          <TheAgric
            key={agric.id}
            agric={agric}
            deleteAgric={deleteAgric}
            viewAgric={viewAgric}
            delAgric={delAgric}
          />
        ))}
        {detail && (
          <DetailAgric
            detailAgric={detailAgric}
            setDetail={setDetail}
            detail={detail}
            handleClose={handleClose}
            agricDetail={agricDetail}
            setAgricDetail={setAgricDetail}
          />
        )}
      </Box>
    </Box>
  );
};

export default AgricList;
