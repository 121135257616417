import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import Header from 'components/Header';
import AppBar from '@mui/material/AppBar';
import Footer from 'components/Footer';
import Banner from './components/Banner';
import Objectives from './components/Objectives';
import Charts from './components/Charts';
import Impact from './components/Impact';
import Join from './components/Join';
import Partners from './components/Partners';
import HowWeWork from './components/HowWeWork';
import Image from '../../svg/banner2.jpg';

const styles = {
  paperContainer: {
    height: 800,
    backgroundImage: `url(${Image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
};

const Home = () => {
  const theme = useTheme();

  return (
    <Box>
      <AppBar
        position={'fixed'}
        sx={{
          backgroundColor: theme.palette.background.paper,
        }}
        elevation={1}
      >
        <Container paddingY={{ xs: 1 / 2, sm: 1/2 }}>
          <Header />
        </Container>
      </AppBar>
      <Box display={{xs:'none', sm:'inline'}}>
        <Banner />
      </Box>
      <Box style={styles.paperContainer} display={{xs:'block', sm:'none'}}>
        <Banner />
      </Box>
      <Box>
        <Container>
          <Objectives />
        </Container>
      </Box>
      <Box>
        <Container>
          <Charts />
        </Container>
      </Box>
      <Box bgcolor={'#81B214'}>
        <Impact />
      </Box>
      <Box>
        <HowWeWork />
      </Box>
      <Box>
        <Container>
          <Join />
        </Container>
      </Box>
      <Box>
        <Container>
          <Partners />
        </Container>
      </Box>
      <Box bgcolor={'#81B214'}>
        <Container>
          <Footer />
        </Container>
      </Box>
    </Box>
  );
};

export default Home;
