import React, { useState } from 'react';
import Box from '@mui/material/Box';
import DetailEducation from './DetailEducation';
import TheEducation from './TheEducation';
import { axiosWithAuth } from 'utils/axiosWithAuth';

const initialEducation = {
  email: '',
  phone: '',
  gender: '',
  age: '',
  education: '',
  occupation: '',
  disability: '',
  city: '',
  lga: '',
  population: '',
  city2: '',
  lga2: '',
  population2: '',
  state: '',
  state2: '',
  edu1: '',
  edu2: '',
  edu3: '',
  edu4: '',
  edu5: '',
  edu6: '',
  edu7: '',
  edu8: '',
  edu9: '',
  edu10: '',
};

const EducationList = ({ educationForms }) => {
  const [detail, setDetail] = useState(false);
  const [educationDetail, setEducationDetail] = useState(initialEducation);
  const [delEducation, setDelEducation] = useState(false);

  const detailEducation = (e) => {
    e.preventDefault();
    axiosWithAuth()
      .get(`/education/${educationDetail.education_id}`)
      .then((res) => {
        setEducationDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const viewEducation = (education) => {
    setDetail(true);
    setEducationDetail(education);
  };
  const handleClose = () => setDetail(false);


  const deleteEducation = (education) => {
    axiosWithAuth()
      .delete(`/education/${education.education_id}`)
      .then((res) => {
        setDelEducation(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <Box>
        {educationForms.map((education) => (
          <TheEducation
            key={education.id}
            education={education}
            deleteEducation={deleteEducation}
            viewEducation={viewEducation}
            delEducation={delEducation}
          />
        ))}
        {detail && (
          <DetailEducation
            detailEducation={detailEducation}
            setDetail={setDetail}
            detail={detail}
            handleClose={handleClose}
            educationDetail={educationDetail}
            setEducationDetail={setEducationDetail}
          />
        )}
      </Box>
    </Box>
  );
};

export default EducationList;
