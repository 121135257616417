import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const data = {
  datasets: [
    {
      data: [10, 20, 25, 10, 15, 5, 15],
      label: 'Community Needs Dashboard',
      backgroundColor: [
        '#206A5D',
        '#36A2EB',
        '#990000',
        '#003865',
        '#F58634',
        '#FFCC29',
        '#81B214',
      ],
      borderWidth: 1,
    },
  ],
  labels: [
    'Agriculture ',
    'Health ',
    'Education ',
    'Environment ',
    'Power & Rural Electrification  ',
    'Water Resources ',
    'Works & Housing ',
  ],
};

const Charts = () => {
  return (
    <Box
      marginBottom={{ xs: '25%', sm: '10%' }}
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      flexWrap={'wrap'}
    >
      <Box
        width={'60%'}
        display={{ xs: 'none', sm: 'inline' }}
        paddingRight={'18%'}
        data-aos={'fade-up'}
      >
        <Typography
          variant="h4"
          color="#81B214"
          fontWeight={700}
          paddingBottom={'4%'}
        >
          Community NEEDS Dashboard
        </Typography>
        <Box width={'100%'}>
          <Doughnut data={data} width={500} />
        </Box>
      </Box>
      <Box
        width={'100%'}
        display={{ xs: 'inline', sm: 'none' }}
        paddingRight={'2%'}
        marginBottom={'25%'}
        data-aos={'fade-up'}
      >
        <Typography
          variant="h4"
          color="#81B214"
          fontWeight={700}
          paddingBottom={'4%'}
          textAlign={'center'}
        >
          Community NEEDS Dashboard
        </Typography>
        <Box width={'100%'}>
          <Doughnut data={data} width={350} />
        </Box>
      </Box>
      <Box
        width={{ xs: '100%', sm: '40%' }}
        display={'flex'}
        flexDirection={'column'}
      >
        <Box
          component={Card}
          padding={4}
          borderRadius={4}
          width={'100%'}
          height={'100%'}
        >
          <Typography
            variant="h4"
            color="#81B214"
            fontWeight={700}
            paddingBottom={'5%'}
          >
            What We Stand For
          </Typography>
          <Typography
            variant="body1"
            color="#4f4f4f"
            paddingBottom={'3%'}
            data-aos={'fade-up'}
          >
            &#8273; Accountable Governance
          </Typography>
          <Typography
            variant="body1"
            color="#4f4f4f"
            paddingBottom={'3%'}
            data-aos={'fade-up'}
          >
            &#8273; Civic Engagement
          </Typography>
          <Typography
            variant="body1"
            color="#4f4f4f"
            paddingBottom={'3%'}
            data-aos={'fade-up'}
          >
            &#8273; NEEDS Assessment Survey
          </Typography>
          <Typography
            variant="body1"
            color="#4f4f4f"
            paddingBottom={'3%'}
            data-aos={'fade-up'}
          >
            &#8273; Inclusive and Participatory Budgeting
          </Typography>
        </Box>
        <Box
          display={{ xs: 'none', sm: 'inline' }}
          marginTop={'5%'}
          data-aos={'fade-up'}
        >
          <div>
            <iframe
              width="453"
              height="280"
              src={'https://www.youtube.com/embed/JlsY18nEmEA'}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default Charts;
