import React from 'react';
import { Grid } from '@mui/material';

const Videos = () => {
  return (
    <Grid container spacing={1} marginTop={'2%'} data-aos={'fade-up'}>
      <Grid item xs={12} sm={6} md={4} paddingBottom={'2%'}>
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={'https://www.youtube.com/embed/JlsY18nEmEA'}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default Videos;
