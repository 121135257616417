import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const items = [
  {
    id: '1',
    image:
      'https://images.unsplash.com/photo-1658129850537-ea7517a9682f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80',
    title: 'Enabling Participatory Budgeting',
    content:
      'We deploy civic-tech solutions to harvest citizens’ voices and document the NEEDS of Nigerian  communities for government budget consideration annually.',
  },
  {
    id: '2',
    image:
      'https://images.unsplash.com/photo-1509099863731-ef4bff19e808?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fGFmcmljYSUyMHZpbGxhZ2VzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    title: 'Evidence-Based Policy Making',
    content:
      'Efficient and effective policy development starts with accurate data and evidences.',
  },
  {
    id: '3',
    image:
      'https://images.unsplash.com/photo-1615027212409-2628cc0cc11a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDd8fGFmcmljYSUyMHZpbGxhZ2VzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60',
    title: 'Driving Inclusive & Sustainable Development',
    content:
      'To achieving sustainable and inclusive budgeting and social protection for citizens in the underserved communities.',
  },
];

const Objectives = () => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap={'wrap'}
      width={{ xs: '100%', sm: '100%' }}
      padding={{ xs: '15% 0%', sm: '10% 0%' }}
    >
      {items.map((item, index) => (
        <Box
          width={{ xs: '100%', sm: '25%' }}
          paddingTop={{ xs: '15%', sm: '0%' }}
          key={index}
          data-aos={'fade-up'}
        >
          <Box
            component="img"
            height={{ xs: '26vh', sm: '28vh' }}
            sx={{
              display: 'block',
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
            }}
            src={item.image}
            alt={'obj_image'}
          />
          <Box padding={{ xs: '0% 5%', sm: '0%' }}>
            <Typography
              variant="h5"
              fontWeight={700}
              paddingTop={'4%'}
              color={'#81B214'}
            >
              {item.title}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              paddingTop={'4%'}
              color={'#4f4f4f'}
            >
              {item.content}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Objectives;
