import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography, Paper } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { axiosWithAuth } from 'utils/axiosWithAuth';

const Youth = () => {
  const [youthList, setYouthList] = useState([]);

  useEffect(() => {
    axiosWithAuth()
      .get('/youth')
      .then((res) => {
        setYouthList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    { name: 'youth_id', label: 'No' },
    {
      name: 'email',
      label: 'Email Address',
      options: { filterOptions: { fullWidth: true }, sort: true, filter: true },
    },
    {
      name: 'gender',
      label: 'Gender',
      options: { filterOptions: { fullWidth: true }, sort: true, filter: true },
    },
    {
      name: 'lga',
      label: 'LGA',
      options: { filterOptions: { fullWidth: true }, sort: true, filter: true },
    },
    {
      name: 'community',
      label: 'Community',
      options: { filter: false, sort: false },
    },
    {
      name: 'others',
      label: 'Others',
      options: { filter: false, sort: false },
    },
    {
      name: 'education',
      label: 'Education',
      options: { filterOptions: { fullWidth: true }, sort: true, filter: true },
    },
    {
      name: 'age',
      label: 'Age',
      options: { filterOptions: { fullWidth: true }, sort: true, filter: true },
    },
    {
      name: 'occupation',
      label: 'Occupation',
      options: { filterOptions: { fullWidth: true }, sort: true, filter: true },
    },
    { name: 'income', label: 'Income', options: { filter: true, sort: false } },
    {
      name: 'priority',
      label: 'Priority',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic1',
      label: 'Civic Question 1',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic2',
      label: 'Civic Question 2',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic3',
      label: 'Civic Question 3',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic4',
      label: 'Civic Question 4',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic5',
      label: 'Civic Question 5',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic6',
      label: 'Civic Question 6',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic7',
      label: 'Civic Question 7',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic8',
      label: 'Civic Question 8',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic9',
      label: 'Civic Question 9',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic10',
      label: 'Civic Question 10',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic11',
      label: 'Civic Question 11',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic12',
      label: 'Civic Question 12',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic13',
      label: 'Civic Question 13',
      options: { filter: false, sort: false },
    },
    {
      name: 'civic14',
      label: 'Civic Question 14',
      options: { filter: false, sort: false },
    },
    {
      name: 'skill',
      label: 'Civic Question 15',
      options: { filter: false, sort: false },
    },
    {
      name: 'violence1',
      label: 'Violence Question 1',
      options: { filter: false, sort: false },
    },
    {
      name: 'violence2',
      label: 'Violence Question 2',
      options: { filter: false, sort: false },
    },
    {
      name: 'violence3',
      label: 'Violence Question 3',
      options: { filter: false, sort: false },
    },
    {
      name: 'violence4',
      label: 'Violence Question 4',
      options: { filter: false, sort: false },
    },
    {
      name: 'violence5',
      label: 'Violence Question 5',
      options: { filter: false, sort: false },
    },
    {
      name: 'violence6',
      label: 'Violence Question 6',
      options: { filter: false, sort: false },
    },
    { name: 'medium', label: 'Medium', options: { filter: true, sort: false } },
  ];

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: 'dropdown',
    responsive: 'simple',
  };

  return (
    <Box marginBottom={{ xs: '25%', sm: '12%' }}>
      <Box
        marginTop={{ xs: '25%', sm: '12%' }}
        paddingLeft={{ xs: '0%', sm: '1%' }}
      >
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            color="#81B214"
            sx={{
              fontWeight: 700,
            }}
          >
            FCT Youth NEEDS Assessment Portal
          </Typography>
        </Box>
      </Box>
      <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
        <MUIDataTable
          title={'Dashboard'}
          data={youthList}
          columns={columns}
          options={options}
        />
      </Paper>
    </Box>
  );
};

export default Youth;
