import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const TwitterFeeds = () => {
  return (
    <Box
      width={{ xs: '100%', sm: '35%' }}
      paddingLeft={{ xs: '0%', sm: '2%' }}
      data-aos={'fade-up'}
      marginTop={{ xs: '35%', sm: '0%' }}
    >
      <Box
        component={Card}
        sx={{ maxHeight: 575, maxWidth: 375, position: 'relative' }}
        overflow={'auto'}
      >
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="grapngr"
          options={{ height: 480 }}
          noFooter={true}
        />
      </Box>
    </Box>
  );
};

export default TwitterFeeds;
