import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Infographics = () => {
  return (
    <Box>
      <Typography
        variant="h6"
        component="p"
        color="#F58634"
        sx={{ fontWeight: 400 }}
      >
        Infographics
      </Typography>
    </Box>
  );
};

export default Infographics;
