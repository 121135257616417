import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { Typography, Grid } from '@mui/material';
import BlogCard from './BlogCard';

const Content = () => {
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    axios
      .get('https://app-grap.herokuapp.com/api/posts')
      .then((res) => {
        setPostList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const blogList = postList.reverse();

  return (
    <Box padding={{ xs: '15% 0%', sm: '7% 0%' }}>
      <Typography
        variant="h3"
        fontWeight={700}
        color={'#F58634'}
        paddingBottom={'2%'}
      >
        Blog
      </Typography>
      <Box>
        <Grid container spacing={2}>
          {blogList.map((post) => (
            <BlogCard key={post.id} post={post} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Content;
