import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GroupsIcon from '@mui/icons-material/Groups';
import about from '../../../svg/about_img.jpg';
import map from '../../../svg/map.png';

const Intro = () => {
  return (
    <Box padding={{ xs: '15% 0%', sm: '7% 0%' }}>
      <Typography
        variant="h3"
        fontWeight={700}
        color={'#F58634'}
        paddingBottom={'4%'}
      >
        About Us
      </Typography>
      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <Box
          width={{ xs: '100%', sm: '55%' }}
          paddingRight={{ xs: '0%', sm: '3%' }}
        >
          <Typography
            variant="body1"
            color={'#4f4f4f'}
            paddingBottom={'3%'}
            data-aos={'fade-down'}
          >
            Over the years, evidence shows that federal, state and local
            governments don’t conduct NEEDS assessment before selecting projects
            to be executed in local and urban communities. This has created
            governance gaps, abandoned projects that do not meet citizens’ needs
            and communities aspirations.
          </Typography>
          <Typography
            variant="body1"
            color={'#4f4f4f'}
            paddingBottom={'3%'}
            data-aos={'fade-up'}
          >
            Against this backdrop, PROMAD has designed ‘Grassroots Advocacy
            Project’ (GRAP) to fill the gap and to engage communities and
            citizens digitally and offline to conduct NEEDS assessment which
            would be adopted in the budget for the people and empower them on
            how to hold the government accountable.
          </Typography>
          <Typography
            variant="body1"
            color={'#4f4f4f'}
            paddingBottom={'3%'}
            data-aos={'fade-up'}
          >
            In realization of OGP objective which is “to ensure the conduct
            annual Needs Assessment Survey; reports would accompany budgets in
            selected sectors such as agriculture, health, education, water
            resources, power, works & housing, environment, etc.” We believe
            that both the government and the people gain when annual budgets are
            created through a transparent process—incorporates peoples’ voices
            and truly focuses on the real development challenges that help to
            meet the 2030 global sustainable development goals (SDGs).
          </Typography>
        </Box>
        <Box width={{ xs: '100%', sm: '45%' }}>
          <Box
            component="img"
            height={{ xs: '30vh', sm: '50vh' }}
            data-aos={'fade-up'}
            sx={{
              display: 'block',
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
            }}
            src={about}
            alt={'about_img'}
          />
        </Box>
      </Box>
      <Typography
        variant="body1"
        color={'#4f4f4f'}
        padding={{ xs: '3% 0%', sm: '0% 0% 3% 0% ' }}
        data-aos={'fade-up'}
      >
        GRAP was established by PROMAD in 2022 with the goal of opening up the
        budget processes for participation through NEEDS assessment surveys and
        engagements in the context of developmental issues and needs affecting
        their communities.
      </Typography>
      <Typography
        variant="body1"
        color={'#4f4f4f'}
        paddingBottom={'3%'}
        data-aos={'fade-up'}
      >
        According to the Open Budget Survey (OBS 2021) by the International
        Budget Partnerships (IBP), “Public Participation in budget
        decision-making fosters better decisions and confidence in government,
        even as tradeoffs are made. Inclusive public budgeting restores public
        trust that the government can deliver.”
      </Typography>
      <Typography
        variant="body1"
        color={'#4f4f4f'}
        paddingBottom={'3%'}
        data-aos={'fade-up'}
      >
        GRAP’s goal is to expand the budget development cycle for inclusive
        participation—contributing to making governmental annual budgets to
        focus on the essential needs of the people based on evidence of needs.
      </Typography>
      <Typography variant="h4" color={'#F58634'} paddingBottom={'3%'}>
        Project Objectives
      </Typography>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        width={'100%'}
      >
        <Box
          width={{ xs: '100%', sm: '30%' }}
          component={Card}
          padding={4}
          borderRadius={4}
          marginBottom={{ xs: '10%', sm: '0%' }}
          data-aos={'fade-up'}
        >
          <Box marginRight={30}>
            <Avatar
              sx={{ width: 56, height: 56, bgcolor: '#81B214', color: '#fff' }}
            >
              <ViewTimelineIcon
                fontSize={'large'}
                sx={{ width: 36, height: 36 }}
              />
            </Avatar>
          </Box>
          <Typography variant="body1" color={'#4f4f4f'} paddingTop={'5%'}>
            To design, develop and deploy tech platforms (website and mobile
            app) to collect Needs Assessment Survey annually.
          </Typography>
        </Box>
        <Box
          width={{ xs: '100%', sm: '30%' }}
          component={Card}
          padding={4}
          borderRadius={4}
          marginBottom={{ xs: '10%', sm: '0%' }}
          data-aos={'fade-up'}
        >
          <Box marginRight={30}>
            <Avatar
              sx={{ width: 56, height: 56, bgcolor: '#81B214', color: '#fff' }}
            >
              <GroupsIcon
                fontSize={'large'}
                sx={{ width: 36, height: 36 }}
              />
            </Avatar>
          </Box>
          <Typography variant="body1" color={'#4f4f4f'} paddingTop={'5%'}>
            To engage communities on data collection for needs assessment using
            our tech platforms and regional offline engagements to empower
            citizens on participatory budgeting and how to demand
            accountability.
          </Typography>
        </Box>
        <Box
          width={{ xs: '100%', sm: '30%' }}
          component={Card}
          padding={4}
          borderRadius={4}
          marginBottom={{ xs: '10%', sm: '0%' }}
          data-aos={'fade-up'}
        >
          <Box marginRight={30}>
            <Avatar
              sx={{ width: 56, height: 56, bgcolor: '#81B214', color: '#fff' }}
            >
              <EqualizerIcon
                fontSize={'large'}
                sx={{ width: 36, height: 36 }}
              />
            </Avatar>
          </Box>
          <Typography variant="body1" color={'#4f4f4f'} paddingTop={'5%'}>
            Analyse, visualize the data collected and produce a National Needs
            Assessment Report (NNAR) for presentation to government MDAs for
            adoption into national and states’ budgets annually.
          </Typography>
        </Box>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        marginTop={'2%'}
        alignItems={'center'}
      >
        <Box
          width={{ xs: '100%', sm: '55%' }}
          paddingRight={{ xs: '0%', sm: '3%' }}
        >
          <Typography
            variant="body1"
            color={'#4f4f4f'}
            paddingTop={'3%'}
            data-aos={'fade-up'}
          >
            GRAP plans to launch out with Ondo state as the pilot and proposes
            to scale to the 36 states of the federation, including the federal
            capital territory (FCT) in two years.
          </Typography>
        </Box>
        <Box width={{ xs: '100%', sm: '45%' }}>
          <Box
            component="img"
            height={{ xs: '40vh', sm: '75vh' }}
            data-aos={'fade-up'}
            sx={{
              display: 'block',
              position: 'relative',
              overflow: 'hidden',
              width: '100%',
            }}
            src={map}
            alt={'about_img'}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Intro;
