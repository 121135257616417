import React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Typography, Link } from '@mui/material';
import needs from 'svg/needs.jpg';

const ColorLink = styled(Link)(() => ({
  color: '#81B214',
  '&:hover': {
    color: '#F58634',
  },
}));

const Intro = () => {
  return (
    <Box padding={{ xs: '25% 0%', sm: '7% 0%' }}>
      <Typography
        variant="h3"
        fontWeight={700}
        color={'#F58634'}
        paddingBottom={'2%'}
      >
        Community NEEDS Assessment Data Survey
      </Typography>
      <Box>
        <Typography
          variant="body1"
          fontWeight={500}
          paddingBottom={'2%'}
          color={'#4f4f4f'}
        >
          Your voice is powerful enough to bring change to your community. Join
          other millions of active Nigerians to supply the needs of your
          community for the production of the National Needs Assessment Report
          (NNAR).
        </Typography>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          flexWrap={'wrap'}
          marginTop={{ xs: '5%', sm: '3%' }}
        >
          <Box width={{ xs: '100%', sm: '40%' }}>
            <Box
              component="img"
              height={{ xs: '33vh', sm: '45vh' }}
              sx={{
                display: 'block',
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
              }}
              src={needs}
              alt={'needs_img'}
            />
          </Box>
          <Box
            width={{ xs: '100%', sm: '48%' }}
            marginTop={{ xs: '8%', sm: '0%' }}
          >
            <Typography
              color={'#81B214'}
              variant="h5"
              fontWeight={700}
              paddingBottom={'2%'}
            >
              Select the sector
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              paddingBottom={'2%'}
              color={'#4f4f4f'}
            >
              Select the sectors in which you want the government to focus on in
              your community.
            </Typography>
            <Box paddingBottom={{ xs: '2%', sm: '1%' }}>
              <ColorLink
                underline="hover"
                component="a"
                href="/NEEDS-assessment-survey-agriculture"
                color={'#81B214'}
                fontWeight={700}
              >
                &#8227; Agriculture and Rural Development
              </ColorLink>
            </Box>
            <Box paddingBottom={{ xs: '2%', sm: '1%' }}>
              <ColorLink
                underline="hover"
                component="a"
                href="/NEEDS-assessment-survey-education"
                color={'#81B214'}
                fontWeight={700}
              >
                &#8227; Education
              </ColorLink>
            </Box>
            <Box paddingBottom={{ xs: '2%', sm: '1%' }}>
              <ColorLink
                underline="hover"
                component="a"
                href="/FCT-Youth-Needs-Assessment-on-Civic-Participation"
                color={'#81B214'}
                fontWeight={700}
              >
                &#8227; FCT Youth Civic Participation
              </ColorLink>
            </Box>
            <Typography
              variant="body1"
              fontWeight={500}
              paddingBottom={{ xs: '2%', sm: '1%' }}
              color={'#4f4f4f'}
            >
              &#8227; Environment
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              paddingBottom={{ xs: '2%', sm: '1%' }}
              color={'#4f4f4f'}
            >
              &#8227; Health
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              paddingBottom={{ xs: '2%', sm: '1%' }}
              color={'#4f4f4f'}
            >
              &#8227; Power and Rural Electrification
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              paddingBottom={{ xs: '2%', sm: '1%' }}
              color={'#4f4f4f'}
            >
              &#8227; Water Resources
            </Typography>
            <Typography
              variant="body1"
              fontWeight={500}
              paddingBottom={{ xs: '2%', sm: '1%' }}
              color={'#4f4f4f'}
            >
              &#8227; Works and Housing
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Intro;
