import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import banner1 from 'svg/banner1.jpg';
import banner2 from 'svg/banner2.jpg';
import banner3 from 'svg/banner3.jpg';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'banner_image1',
    imgPath: banner1,
    title: 'Grassroots Advocacy Project — GRAP',
    text: 'GRAP is a civic-tech initiative for budget participation by PROMAD — using tech to document the NEEDS of Nigerian communities for adoption into the annual budget.',
  },
  {
    label: 'banner_image2',
    imgPath: banner2,
    title: 'NEEDS assessment, participation & transparency',
    text: 'Focusing on citizens’ essential needs across the underserved and underrepresented communities for inclusion to grow the economy ',
  },
  {
    label: 'banner_image3',
    imgPath: banner3,
    title: 'We can make budget work for the people',
    text: 'Tell us about your community needs for government budget consideration',
  },
];

function Banner() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const ColorButton = styled(Button)(() => ({
    color: '#fff',
    width: 225,
    height: 55,
    fontWeight: 600,
    fontSize: '16px',
    textTransform: 'capitalize',
    backgroundColor: '#F58634',
    borderColor: '#F58634',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#81B214',
      borderColor: '#81B214',
    },
  }));

  return (
    <Box sx={{ flexGrow: 1 }} marginTop={{ xs: '15%', sm: '3%' }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box>
                <Box
                  component="img"
                  height={{ xs: '30vh', sm: '130vh' }}
                  display= {{xs:'none', sm: 'block'}}
                  sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                  src={step.imgPath}
                  alt={step.label}
                />
                <Box
                  display={{ xs: 'none', sm: 'inline' }}
                  paddingBottom={'3%'}
                  position={'absolute'}
                  top={{ xs: '55%', sm: '25%' }}
                  padding={{ xs: '0%', sm: '0%' }}
                  marginLeft={{ xs: '0%', sm: '0%' }}
                  width={'80%'}
                >
                  <Typography
                    variant="h2"
                    color="#fff"
                    fontWeight={400}
                    padding={'1% 2% 1% 15%'}
                  >
                    {step.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="#fff"
                    fontWeight={500}
                    padding={'1% 2% 1% 15%'}
                  >
                    {step.text}
                  </Typography>
                  <Box padding={'1% 2% 1% 15%'}>
                    <ColorButton
                      component={'a'}
                      variant="outlined"
                      size="medium"
                      href={'/aboutus'}
                    >
                      Read More
                    </ColorButton>
                  </Box>
                </Box>
                <Box
                  display={{ xs: 'block', sm: 'none' }}
                  paddingBottom={'3%'}
                  width={'90%'}
                >
                  <Typography
                    variant="h3"
                    color="#fff"
                    fontWeight={700}
                    padding={'35% 3% 1% 3%'}
                    textAlign={'left'}
                  >
                    {step.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="#fff"
                    fontWeight={500}
                    padding={'5% 3% 1% 3%'}
                    textAlign={'left'}
                  >
                    {step.text}
                  </Typography>
                  <Box padding={'10% 2% 1% 4%'}>
                    <ColorButton
                      component={'a'}
                      variant="outlined"
                      size="medium"
                      href={'/aboutus'}
                    >
                      Read More
                    </ColorButton>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
    </Box>
  );
}

export default Banner;
