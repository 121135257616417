import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const DetailPost = ({ style, setDetail, postDetail }) => {
  return (
    <Box style={style} margin={'2% 0%'} paddingLeft={{ xs: '0%', sm: '3%' }}>
      <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
        Title: {postDetail.title}
      </Typography>
      <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
        Introduction: {postDetail.caption}
      </Typography>
      <Typography variant={'subtitle2'} sx={{ marginBottom: 1, textTransform: 'capitalize' }}>
        Category: {postDetail.category}
      </Typography>
      <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
        Date: {postDetail.date}
      </Typography>
      <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
        Author: {postDetail.author}
      </Typography>
      <Button
        color={'error'}
        variant={'contained'}
        onClick={() => setDetail(false)}
      >
        cancel
      </Button>
    </Box>
  );
};

export default DetailPost;
