import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DetailEducation = ({
  handleClose,
  setDetail,
  detail,
  educationDetail,
}) => {
  return (
    <Box margin={'2% 0%'} paddingLeft={{ xs: '0%', sm: '3%' }}>
      <Modal
        open={detail}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            display="flex"
            justifyContent={'space-between'}
            alignItems={'flex-start'}
          >
            <Box width={'45%'}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Email Address: {educationDetail.email}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Phone Number: {educationDetail.phone}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
              Gender:{educationDetail.gender}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
               Age: {educationDetail.age}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
               Education: {educationDetail.education}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Occupation:{educationDetail.occupation}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                 Disability: {educationDetail.disability}
              </Typography>
              <Typography
                variant={'subtitle2'}
                fontWeight={700}
                sx={{ marginBottom: 1 }}
              >
                Current State of Residence
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                State:{educationDetail.state}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
               City:{educationDetail.city}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Local Govt: {educationDetail.lga}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Estimated Population:
                {educationDetail.population}
              </Typography>
              <Typography
                variant={'subtitle2'}
                fontWeight={700}
                sx={{ marginBottom: 1 }}
              >
                Original State of Origin
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                State: {educationDetail.state2}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                City: {educationDetail.city2}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Local Govt: {educationDetail.lga2}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Estimated Population:
                {educationDetail.population2}
              </Typography>
            </Box>
            <Box width={'45%'}>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 1: {educationDetail.edu1}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 2: {educationDetail.edu2}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 3: {educationDetail.edu3}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 4: {educationDetail.edu4}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 5: {educationDetail.edu5}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 6: {educationDetail.edu6}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 7: {educationDetail.edu7}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
              Question 8: {educationDetail.edu8}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 9: {educationDetail.edu9}
              </Typography>
              <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
                Question 10: {educationDetail.edu10}
              </Typography>
            </Box>
          </Box>
          <Box margin={'2%'}>
            <Button
              color={'error'}
              variant={'contained'}
              onClick={() => setDetail(false)}
            >
              cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DetailEducation;
