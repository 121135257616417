import React from 'react';
import Box from '@mui/material/Box';
import Container from 'common/Container';
import {  Form } from './components';

const Login = () => {
  return (
    <Box position={'relative'} minHeight={'80vh'} display={'flex'} marginLeft={{ xs: '0%', sm: '5%' }}>
      <Box
        flex={{ xs: '1 1 100%', md: '1 1 70%' }}
        maxWidth={{ xs: '100%', md: '35%' }}
      >
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <Container maxWidth={800}>
            <Form />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
