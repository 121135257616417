import * as React from 'react';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import { blue, red } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import FileOpenIcon from '@mui/icons-material/FileOpen';

const ThePost = ({ post, deletePost, viewPost }) => {

  const handleDelete = (e) => {
    e.stopPropagation();
    deletePost(post);
  };

  return (
    <Box paddingLeft={{ xs: '0%', sm: '1%' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell style={{ width: 5 }} component="th" scope="row">
                {post.post_id}
              </TableCell>
              <TableCell align="left" style={{ width: 300 }}>{post.title}</TableCell>
              <TableCell align="left" style={{ width: 400 }}>{post.caption}</TableCell>
              <TableCell align="left" style={{ width: 200, textTransform: 'capitalize' }}>{post.category}</TableCell>
              <TableCell align="left" style={{ width: 160 }}>{post.date}</TableCell>
              <TableCell align="left" style={{ width: 200 }}>{post.author}</TableCell>
              <TableCell align="right" style={{ width: 5}}>
                <IconButton onClick={handleDelete} title="Delete">
                  <DeleteIcon sx={{ color: red[700] }}/>
                </IconButton>
              </TableCell>
              <TableCell align="right" style={{ width: 5 }}>
                <IconButton onClick={() => viewPost(post)} title="View">
                  <FileOpenIcon
                    sx={{ color: blue[700] }}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ThePost;
