import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PostList from './PostList';
import DetailPost from './DetailPost';
import { axiosWithAuth } from 'utils/axiosWithAuth';

const PostPortal = () => {
  const [postList, setPostList] = useState([]);

  useEffect(() => {
    axiosWithAuth()
      .get('/posts')
      .then((res) => {
        setPostList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  });

  return (
    <Box marginBottom={{xs:'25%', sm:'12%'}} >
      <Box marginTop={{xs:'25%', sm:'12%'}} paddingLeft={{ xs: '0%', sm: '1%' }}>
        <Box marginBottom={4}>
          <Typography
            variant="h4"
            color="#81B214"
            sx={{
              fontWeight: 700,
            }}
          >
            Blogs Board
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell  style={{ width: 5, fontWeight:700, color:'#F58634'}} >No</TableCell>
                <TableCell style={{ width: 310, fontWeight:700, color:'#F58634' }} align="left">Title</TableCell>
                <TableCell  style={{ width: 400, fontWeight:700, color:'#F58634' }} align="left">Introduction</TableCell>
                <TableCell  style={{ width: 200, fontWeight:700, color:'#F58634' }} align="left">Category</TableCell>
                <TableCell style={{ width: 200, fontWeight:700, color:'#F58634' }} align="left">Date</TableCell>
                <TableCell  style={{ width: 200, fontWeight:700, color:'#F58634' }}align="left">Author</TableCell>
                <TableCell style={{ width: 150, fontWeight:700,  color:'#F58634' }}  align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Box>
      <Box>
        <Switch>
          <Route path="/admin/post-portal/view">
            <DetailPost />
          </Route>
          <Route path="/admin/post-portal">
            <PostList posts={postList} updatePost={setPostList} />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default PostPortal;
